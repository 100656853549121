/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import {
  GET_STATISTICS
} from 'src/actions/dashboardActions';

const initialState = {
  statistics: null  
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATISTICS: {
      const { statistics }  = action.payload;

      return produce(state, (draft) => {
        draft.statistics = statistics;
      });
    }

    default: {
      return state;
    }
  }
};

export default dashboardReducer;
