import { Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';

const useCheckboxStyles = makeStyles(theme => ({
  myCheckbox: {
    color: theme.palette.text.primary
  }
}));

export default function CheckboxInput({ label, checked, className, onChange, ...rest }) {
  const classes = useCheckboxStyles();

  const handleChange = e => {
    // console.log({ e });
    onChange && onChange(e.target.checked);
  };

  return <FormControlLabel
    label={label}
    className={clsx(classes.myCheckbox, className)}
    onChange={handleChange}
    control={<Checkbox checked={checked} />}
    {...rest}
  />;
}
