import React, { } from 'react';
import PropTypes from 'prop-types';
import {
  Typography
} from '@mui/material';

function DashboardCardTitle({
  className, title, ...rest
}) {
  return <Typography
    component="h3"
    gutterBottom
    variant="overline"
    color="textSecondary"
    {...rest}
  >
    {title}
  </Typography>;
}

DashboardCardTitle.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
};

export default DashboardCardTitle;
