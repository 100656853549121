import React from 'react';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import MarkerIcon from "./MarkerIcon";
import Typography from 'src/theme/typography';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useMarkerStyles = makeStyles({
  marker: {
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      height: 'auto'
    },
    '&.leaflet-marker-icon': {
      display: 'flex'
    }
  }
})

export const markerNotificationClasses = {
  0: '',
  1: 'leaflet-custom-marker-notify',
  2: 'leaflet-custom-marker-notify leaflet-custom-marker-notify-warning',
  3: 'leaflet-custom-marker-notify leaflet-custom-marker-notify-error'
};

export default function DraggableMarker({ draggable, onPositionChange, position, icon, popupContent, iconAnchor, iconSize, messageSeverity }) {
  // const classes = useMarkerStyles();

  const map = useMapEvents({
    click(e) {
      if (!position || draggable)
        onPositionChange(e.latlng);
    }
  });

  const content = React.useMemo(() => {
    const iconWidth = iconSize ? iconSize.x : 32;
    const iconHeight = iconSize ? iconSize.y : 32;

    const anchorX = iconAnchor ? iconWidth * iconAnchor.x : 16;
    const anchorY = iconAnchor ? iconHeight * iconAnchor.y : 32;

    const className = clsx('leaflet-custom-marker', markerNotificationClasses[messageSeverity]);

    return divIcon({
      html: renderToStaticMarkup(<MarkerIcon icon={icon} />),
      iconAnchor: {
        x: anchorX,
        y: anchorY
      },
      className,
      iconSize: {
        x: iconWidth,
        y: iconHeight
      },
      popupAnchor: {
        x: 0,
        y: -anchorY
      }
    });
  }, []);

  const eventHandlers = React.useMemo(
    () => ({
      dragend(e) {
        onPositionChange(e.target.getLatLng());
      }
    }),
    []
  );

  return position
    ? <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      icon={content}
      messageSeverity={messageSeverity}
    >
      {popupContent && <Popup>
        {popupContent}
      </Popup>}
    </Marker>
    : null;
}
