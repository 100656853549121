import axios from 'axios';

const instance = axios.create();

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 403) {
      if (error.response.config.url.indexOf('login') === -1)
        window.location = '/403';
    }
    return Promise.reject(error.response);
  }
);

export default instance;
