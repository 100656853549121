import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { makeStyles } from '@mui/styles';
import React from 'react'
import PropTypes from "prop-types"
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  icon: ({ marginRight }) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: (marginRight),
  })
}))

export default function Icon({ icon, color, marginRight = 0, size, className, ...rest }) {
  const classes = useStyles({ marginRight });
  return (
    <FontAwesomeIcon color={color} size={size || 'lg'} icon={['fas', icon]} fixedWidth className={clsx(classes.icon, className)} {...rest} />
  )
}

export function SwitchableIcon({ switchIcons, icon1, icon2 }) {
  return <Icon icon={switchIcons ? icon2 : icon1} />;
}

/*Icon.defaultProps = {
  marginRight: 0,
  // style: 'fas'
}*/

// Icon.propTypes = {
//   style: PropTypes.oneOf(['fas', 'far'])
// }