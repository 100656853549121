import React from 'react';
import clsx from 'clsx';

export function Center({ vertical, horizontal, children }) {

  const className = clsx(
    'centering-box',
    horizontal ? 'center-horizontal' : '',
    vertical ? 'center-vertical' : ''
  );

  return <div className={className}>
    {children}
  </div>;
}
