import React from 'react';
import Label from 'src/components/Label';
import { SERVICE_STATUS } from 'src/constants';

export function ApplicationStatusLabel({ statusCode }) {
  const statusColor = (() => {
    switch (statusCode) {
      case SERVICE_STATUS.WARNING:
        return 'warning';
      case SERVICE_STATUS.CRITICAL:
        return 'error';
      case SERVICE_STATUS.RUNNING:
      case SERVICE_STATUS.OK:
        return 'success';
      case SERVICE_STATUS.UNKNOWN:
      default:
        return 'neutral';
    }
  })();

  const statusText = (() => {
    switch (statusCode) {
      case SERVICE_STATUS.RUNNING:
        return 'Kör';
      case SERVICE_STATUS.WARNING:
        return 'Varning';
      case SERVICE_STATUS.CRITICAL:
        return 'Kritisk';
      case SERVICE_STATUS.OK:
        return 'OK';
      case SERVICE_STATUS.UNKNOWN:
      default:
        return 'Okänd';
    }
  })();

  return <Label
    color={statusColor}
  >
    {statusText}
  </Label>;
}
