import React, { useState,useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { getNotificationSettings } from 'src/actions/accountActions';
import {
  Box,
  Container,
  Divider,
  Tab,
  Tabs,
  Card,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import Page from 'src/components/Page';
import Header from './Header';
import General from './General';
import Notifications from './Notifications';
import Security from './Security';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  }
}));







function AccountView() {
  const { user } = useSelector(state => state.account);
  const classes = useStyles();

  const initialTab = new URLSearchParams(location.search).get('tab') || 'general';
  const [currentTab, setCurrentTab] = useState(initialTab);
  //const [currentTab, setCurrentTab] = useState('general');
  /*const tabs = [
    { value: 'general', label: 'Allmänt' },
    { value: 'notifications', label: 'Notifieringar' },
    { value: 'security', label: 'Säkerhet' }
  ];*/

  
  const dispatch = useDispatch();
  const { notificationsettings } = useSelector((state) => state.account);

  useEffect(() => {
    dispatch(getNotificationSettings());    
  }, [dispatch]);


  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
    {user.changeOnNextLogin !== true && 
    <Page className={classes.root} title="Inställningar">
      <Container maxWidth="lg">
        <Box mt={3}>
          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="secondary"
            className={classes.tabs}
          >
            <Tab value="general" label="Allmänt" />
            <Tab value="notifications" label="Notifieringar" />
            <Tab value="security" label="Säkerhet" />
          </Tabs>
        </Box>
        <Divider />
        <Box mt={3}>
          {currentTab === 'general' && <General />}
          {currentTab === 'notifications' && <Notifications notificationsettings={notificationsettings} />}
          {currentTab === 'security' && <Security />}
        </Box>
      </Container>
    </Page>
    }{user.changeOnNextLogin === true && 
      <Page className={classes.root} title="Inställningar">
        <Container maxWidth="lg">
          <Box mt={3}>
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="secondary"
              className={classes.tabs}
            >
              <Tab value="security" label="Säkerhet" />
            </Tabs>
          </Box>
          <Divider />
          <Box mt={3}>
          <Card className={classes.infoCard} sx={{mb: 1, p: 2}}>
              <Typography style={{ fontWeight: 'bold' }}>
              Du måste byta lösenord innan du kan fortsätta!
              </Typography>
          </Card>
            {currentTab === 'security' && <Security />}
          </Box>
        </Container>
      </Page>
      }
    </>
  );
}

export default AccountView;
