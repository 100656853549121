import { IconButton } from "@mui/material";
import EventIcon from '@mui/icons-material/Event'; 
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import React, { useState, useEffect } from "react";
import moment from "moment";

const CalendarPicker = ({ date, onDateChanged, minDate, maxDate, resolution, children, ...rest }) => {

  const [open, setOpen] = useState(false);
  const [childComponent, setChildComponent] = useState(false);

  useEffect(() => {
    if (children)
      setChildComponent(React.cloneElement(children, { onClick: handleOpen }))
  }, [children]);

  const handleChange = date => {
    const newDate = moment(date).toDate(); 
    onDateChanged(newDate);
  };

  const views = () => {
    if (['year', 'month', 'day'].indexOf(resolution) === -1)
      return ['year', 'month'];

    switch (resolution) {
      case 'year': return ['year'];
      case 'day': return ['day'];
      default:
      case 'month': return ['year', 'month'];
    }
  };

  const buttonRef = React.useRef(null);

  const handleOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {childComponent
        ? childComponent
        : <IconButton ref={buttonRef} onClick={handleOpen}>
          <EventIcon />
        </IconButton>
      }

      <MobileDatePicker
        open={open}
        onClose={handleClose}
        value={moment(date)}
        //onChange={handleChange}
        onAccept={handleChange}
        views={views()}
        slotProps={{
          textField: {
            sx: {
              display: 'none',
            },
          },
        }}
        textField={() => null}
        
        minDate={minDate ? moment(minDate) : undefined}
        maxDate={maxDate ? moment(maxDate) : undefined}
      />
    </div>
  );
};

export default CalendarPicker;