import React, {  } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Card
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}));

function DashboardCard({
  className, children, ...rest
}) {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      {children}
    </Card>
  );
}

DashboardCard.propTypes = {
  className: PropTypes.string
};

export default DashboardCard;
