import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import {
  ThemeProvider
} from '@mui/material/styles';  // Uppdaterad import från MUI v5
import { StyledEngineProvider } from '@mui/material';  // För att hantera JSS-stöd i MUI
import { LocalizationProvider } from '@mui/x-date-pickers';  // Ny från @mui/x-date-pickers
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Auth from 'src/components/Auth';
import CookiesNotification from 'src/components/CookiesNotification';
import SettingsNotification from 'src/components/SettingsNotification';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import RoutesComponent from 'src/Routes';
import 'moment/locale/sv';
import { jssPreset } from '@mui/styles';
import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/styles';
import { GlobalStyles } from '@mui/system';
import 'moment/locale/sv';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const globalStyles = (
  <GlobalStyles
    styles={(theme) => ({
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
      },
      html: {
        'WebkitFontSmoothing': 'antialiased',
        'MozOsxFontSmoothing': 'grayscale',
        height: '100%',
        width: '100%',
      },
      a: {
        textDecoration: 'none !important',
      },
      button: {
        textDecoration: 'none !important',
      },
      body: {
        height: '100%',
        width: '100%',
      },
      '#root': {
        height: '100%',
        width: '100%',
      },
      '.k-grid': {
        backgroundColor: theme.palette.background.default,
      },
      '.centering-box': {
        display: 'flex',
        '&.center-horizontal': {
          justifyContent: 'center',
        },
        '&.center-vertical': {
          alignItems: 'center',
        },
      },
    })}
  />
);

const useStyles = makeStyles((theme) => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      'WebkitFontSmoothing': 'antialiased',
      'MozOsxFontSmoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      height: '100%',
      width: '100%'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '.k-grid': {
      backgroundColor: theme.palette.background.default
    },
    '.centering-box': {
      display: 'flex',
      '&.center-horizontal': {
        justifyContent: 'center'
      },
      '&.center-vertical': {
        alignItems: 'center'
      }
    }
  }
}));

function App() {

  const { settings } = useSettings();

  return (
    <ThemeProvider theme={createTheme(settings)}>
      <StyledEngineProvider injectFirst>
        {globalStyles}
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="sv" locale="sv">
          <SnackbarProvider maxSnack={3}>
            <Router history={history}>
              <Auth>
                <ScrollReset />
                <GoogleAnalytics />
                {/* <CookiesNotification />
                <SettingsNotification /> */}
                <RoutesComponent />
              </Auth>
            </Router>
          </SnackbarProvider>
        </LocalizationProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  );
}

export default App;
