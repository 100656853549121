/* eslint-disable react/no-array-index-key */
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Routes,
  Navigate,
  Route
} from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import AdminGuard from './components/AdminGuard';
import { element } from 'prop-types';
import DashboardView from 'src/views/dashboards';
import { useSelector } from 'react-redux';
import AccountView from 'src/views/pages/AccountView';
import ChangePasswordForm from 'src/views/auth/ForgotPasswordView/ChangePasswordForm';
import TimestampGuard from './components/TimeStampGuard';

const routesConfig = [
  {
    path: '/',
    element: () => <Navigate to="/app" />
  },
  {
    path: '/403',
    element: lazy(() => import('src/views/pages/Error403View'))
  },
  {
    path: '/404',
    element: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    guard: GuestGuard,
    path: '/login',
    element: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    guard: GuestGuard,
    path: '/forgotpassword',
    element: lazy(() => import('src/views/auth/ForgotPasswordView'))
  },
  {
    guard: GuestGuard,
    path: '/changepassword',
    element: () => (
      <TimestampGuard>
        <ChangePasswordForm />
      </TimestampGuard>
    )
  },
  {
    path: '/login-unprotected',
    element: lazy(() => import('src/views/auth/LoginView'))
  },
  {
    guard: GuestGuard,
    path: '/register',
    element: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/register-unprotected',
    element: lazy(() => import('src/views/auth/RegisterView'))
  },
  {
    path: '/app/*',
    guard: AuthGuard,
    layout: DashboardLayout,
    children: [
      {
        path: '/',
        element: () => <Navigate to="/app/dashboard" />
      },
      {
        path: '/account',
        element: lazy(() => import('src/views/pages/AccountView'))
      },
      {
        path: '/dashboard',
        element: lazy(() => import('src/views/dashboards'))
      },
      {
        path: '/users',
        element: lazy(() => import('src/views/objects/UserListView'))
      },
      {
        path: '/apiusers',
        element: lazy(() => import('src/views/objects/APIUserListView'))
      },
      {
        path: '/import',
        element: lazy(() => import('src/views/objects/ImportView'))
      },
      {
        path: '/log',
        element: lazy(() => import('src/views/objects/LogView'))
      },
      {
        path: '/map',
        element: lazy(() => import('src/views/objects/MapView'))
      },
      {
        path: '/propertyowner/:id/createproperty',
        element: lazy(() => import('src/views/objects/PropertyCreateView'))
      },
      {
        path: '/property/:id/createapartment',
        element: lazy(() => import('src/views/objects/ApartmentCreateView'))
      },
      {
        path: '/property/:id/createmechanicalroom',
        element: lazy(() => import('src/views/objects/MechanicalRoomCreateView'))
      },
      {
        path: '/property/:id',
        element: lazy(() => import('src/views/objects/PropertyView'))
      },
      {
        path: '/property/:id/:tab',
        element: lazy(() => import('src/views/objects/PropertyView'))
      },
      {
        path: '/apartment/:id',
        element: lazy(() => import('src/views/objects/ApartmentView'))
      },
      {
        path: '/apartment/:id/:tab',
        element: lazy(() => import('src/views/objects/ApartmentView'))
      },
      {
        path: '/mechanicalroom/:id',
        element: lazy(() => import('src/views/objects/MechanicalRoomView'))
      },
      {
        path: '/mechanicalroom/:id/:tab',
        element: lazy(() => import('src/views/objects/MechanicalRoomView'))
      },
      {
        path: '/propertyowner/:id/',
        element: lazy(() => import('src/views/objects/PropertyOwnerSummaryView'))
      },
      {
        path: '/propertyowner/:id/:tab',
        element: lazy(() => import('src/views/objects/PropertyOwnerSummaryView'))
      },
      {
        path: '/createpropertyowner/*',
        children: [
          {
            path: '/',
            guard: AdminGuard,
            element: lazy(() => import('src/views/objects/PropertyOwnerCreateView'))
          }
        ]
      },
      {
        path: '/debit/*',
        children: [
          {
            path: '/',
            guard: AdminGuard,
            element: lazy(() => import('src/views/objects/DebitView'))
          }
        ]
      },
      {
        path: '/statuses/*',
        children: [
          {
            path: '/',
            guard: AdminGuard,
            element: lazy(() => import('src/views/objects/StatusView'))
          }
        ]
      },
      {
        path: '/modbusstatuses',
        element: lazy(() => import('src/views/objects/ModbusStatusView'))
      },
      {
        element: () => <Navigate to="/404" />
      }
    ]
  },
  {
    path: '/bkab.se',
    element: () => { window.location = 'http://bkab.se'; return null; }
  },
  {
    path: '*',
    layout: MainLayout,
    children: [
      {
        element:  () => <Navigate to="/404" />
      }
    ]
  }
];

const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {/*<Route path="/" element={<Navigate to="/app" />} />
      <Route path="/app" element={<Navigate to="/app/dashboard" />} />*/}
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Element = route.element;

        return (
          <Route
            key={i}
            path={route.path}
            element={
              <Guard>
                <Layout>
                  {route.children ? renderRoutes(route.children) : <Element />}
                </Layout>
              </Guard>
            }
          />
        );
      })}
    </Routes>
  </Suspense>
) : null);

const renderChangeRoute = () => (
  <Suspense fallback={<LoadingScreen />}>
    <Routes>
      {/* Renderar AccountView direkt och skickar till säkerhetsfliken */}
      <Route path="/app/account" element={<AccountView />} />
      <Route path="*" element={<Navigate to="/app/account?tab=security" />} />
    </Routes>
  </Suspense>
);


function RoutesComponent() {
  const { user } = useSelector(state => state.account);

  if (user && user.changeOnNextLogin === true) {
    return renderChangeRoute();
  } else {
    return renderRoutes(routesConfig);
  }
}

export default RoutesComponent;
