import { parseDate } from '@telerik/kendo-intl';
import moment from 'moment';

export function startOfHour(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), 0, 0);
}

export function startOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth(), 1, 0);
}

export function endOfMonth(date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0, 0);
}

export function monthsBetween(startDate, endDate) {
  return moment(startDate).diff(moment(endDate), 'months');
}

export function startOfYear(date) {
  return new Date(date.getFullYear(), 0, 1);
}

export function endOfYear(date) {
  return new Date(date.getFullYear(), 11, 31);
}

export function getDateToString(date) {
  if (!(date instanceof Date) || isNaN(date)) {
    // Returnera en tom sträng eller annan standardvärde om datumet är ogiltigt
    return '';
  }

  // Justera tidszon och formatera som YYYY-MM-DD
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date.toISOString().slice(0, 10).replace(/[^0-9]/g, '-');
}

export function getDateToLongMonthString(date) {
  if (!date)
    return '';
  const monthNames = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'];
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

  return monthNames[date.getMonth()];
}

export function getDateToShortMonthString(date) {
  const monthNames = ['Jan', 'Feb', 'Mars', 'Apr', 'Maj', 'Juni', 'Juli', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'];
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

  return monthNames[date.getMonth()];
}

export function getDateToDateOfMonthString(date) {
  if (!date)
    return '';
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return date.getDate();
}

export function getDateTimeToString(indate, excludeSeconds) {
  let date;

  if (typeof indate === 'string' && indate === '0001-01-01T00:00:00') {
    return '';
  }

  if (typeof indate === 'string') {
    date = new Date(indate);
  } else {
    date = indate;
  }

  // Kontrollera om date är ogiltig
  if (!date || isNaN(date.getTime())) {
    return ''; // Återvänd tom sträng om datumet är ogiltigt
  }

  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  
  const datestr = `${date.toISOString().slice(0, 10)} ${date.toISOString().slice(11, 19 - (excludeSeconds ? 3 : 0))}`;
  return datestr;
}


// export function getDateToString(indate){
//   return getDateTimeToString(indate).slice(0, 10);
// }

export function getDateTimeFromAPIDate(strdate) {
  return parseDate(strdate, 'yyyy-MM-ddTHH:mm:ss');
}

export function getDateFromStringDate(strdate) {
  return parseDate(strdate, 'yyyy-MM-dd');
}

export function nextDebitDate(start, now, monthInterval) {
  if (!(start && monthInterval))
    return moment('0001-01-01T00:00:00');

  if (!(start instanceof moment))
    start = moment(start);

  if (!(now instanceof moment))
    now = moment(now);

  // console.log({ start, now });

  if (start >= now)
    return start;


  const monthsBetween = monthsBetween(start, now);
  const dayOffset = start.diff(now, 'days');
  const monthOffset = monthsBetween % monthInterval;
  now = moment([now.year(), now.month(), start.day()]);
  return dayOffset <= 0 && monthOffset == 0
    ? now
    : now.add(monthInterval - monthOffset, 'months');
}

export function closestDebitDate(start, now, monthInterval) {
  start = moment(start);
  now = moment(now);

  start.set('date', 1);

  if (now <= start)
    return start;

  let monthsBetween = (start.diff(now, 'months', true));
  // console.log({ monthsBetween });
  // const period = Math.floor(Math.ceil(Math.abs(monthsBetween)) / monthInterval);
  const period = Math.floor(Math.ceil(Math.abs(monthsBetween)) / monthInterval);

  // now.set('date', start.date());
  // start.add(Math.floor((Math.abs(monthsBetween) + (now.date() < start.date()) * -1) / monthInterval) * monthInterval, 'months ');
  // return start;

  start.add(period * monthInterval, 'months');
  // console.log({ start });

  return start;
}
