import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { USER_ROLES } from 'src/constants';

export default function AdminGuard({ children }) {
  const { user } = useSelector((state) => state.account);

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!user.usertype === USER_ROLES.SUPERADMIN) {
    return <Navigate to="/403" />;
  }

  return children;
}
