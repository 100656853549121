import React, { useCallback, useEffect, useMemo, useState } from 'react';
import L from 'leaflet';
import MapIconButton from "../MapIconButton";
import { POSITION_CLASSES } from './positions';
import { OBJECT_TYPES } from 'src/constants';

const filterButtons = [
  {
    name: 'Fastigheter',
    icon: 'building',
    key: OBJECT_TYPES.PROPERTY
  },
  {
    name: 'Undercentraler',
    icon: 'network-wired',
    key: OBJECT_TYPES.MECHANICALROOM
  },
  {
    name: 'Laddstolpar',
    icon: 'charging-station',
    key: OBJECT_TYPES.CHARGINGPOINT
  },
];

export default function FilterControl({ position, onFilterChange, removeFilterList, filter: _filter }) {
  const [toggleState, setToggleState] = useState({});
  const [filter, setFilter] = useState(_filter);

  useEffect(() => {
    onFilterChange && onFilterChange(filter);
  }, [filter.length]);

  const handleUpdateState = (key, state) => {
    setFilter(filter => {
      return state === true
        ? filter.filter(k => k !== key).concat([key])
        : filter.filter(k => k !== key);
    })
  }

  const containerRef = useCallback((node) => {
    if (node)
      L.DomEvent.disableClickPropagation(node);
  }, []);

  const ToggleButton = ({ icon, title, buttonKey: key, onClick }) => {
    const [enabled, setEnabled] = useState(true);

    useEffect(() => {
      onClick && onClick(key, enabled);
    }, [enabled]);

    const handleClick = () => {
      setEnabled(enabled => {
        return !enabled;
      });
    }

    return <MapIconButton
      allowClick
      className="leaflet-toggle-button"
      key={key}
      title={title}
      disabled={!enabled}
      onClick={handleClick}
      icon={icon}
    />;
  };

  const memoizedButtons = useMemo(() => {
    return filterButtons.filter(f => removeFilterList ? removeFilterList.includes(f.key) : true).map(filterButton => (
      <ToggleButton
        title={filterButton.name}
        icon={filterButton.icon}
        buttonKey={filterButton.key}
        key={filterButton.key}
        onClick={handleUpdateState}
      />
    ))
  }, [removeFilterList]);

  const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.bottomleft;

  return (
    <div className="leaflet-control-container" ref={containerRef}>
      <div className={positionClass}>
        <div className="leaflet-control leaflet-bar">
          {memoizedButtons}
        </div>
      </div>
    </div>
  );
}
