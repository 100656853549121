import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  Divider
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Search as SearchIcon,
  XCircle as XIcon
} from 'react-feather';
import axios from 'src/utils/axios';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  },
  groupTitle: {
    marginBottom: theme.spacing(2)
  }
}));

const titles = {
  1: 'Fastighetsägare',
  2: 'Fastigheter',
  3: 'Lägenheter',
  4: 'Sensorer',
  5: 'Undercentraler',
  6: 'Användare',
}

const paths = {
  1: 'propertyowner/{id}',
  2: 'property/{id}',
  3: 'apartment/{id}',
  4: 'apartment/{id}/sensors',
  5: 'property/{id}/mechanicalrooms',
  6: 'users?u={id}',
}

const icons = {
  1: 'Fastighetsägare',
  2: 'Fastigheter',
  3: 'Lägenheter',
  4: 'Sensorer',
  5: 'Undercentraler',
  6: 'Användare',
}

function Search() {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      const baseUrl = process.env.REACT_APP_API_BASE_URL;
      const url = `${baseUrl}/api/search`;
      const body = { search: { searchvalue: value } };
      const response = await axios.post(url, body);

      //  console.log(response);



      setResults(response.data.groups);
    } catch (error) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const buildPath = (type, id) => {
    let path = paths[type];
    path = path.replace('{id}', id);
    return `/app/${path}`;
  };

  const handleTextFieldKeyDown = event => {
    if (event.key == 'Enter')
      handleSearch();
  };

  return (
    <>
      <Tooltip title="Sök">
        <IconButton
          color="inherit"
          onClick={handleOpen}
        >
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        // ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                variant="h4"
                color="textPrimary"
              >
                Sök
              </Typography>
              <IconButton onClick={handleClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextField
                className={classes.queryField}
                fullWidth
                onKeyDown={handleTextFieldKeyDown}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon
                        fontSize="small"
                        color="action"
                      >
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={(event) => setValue(event.target.value)}
                placeholder="Sök"
                value={value}
                variant="outlined"
              />
            </Box>
            <Box
              mt={2}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSearch}
              >
                Sök
              </Button>
            </Box>
            <Box mt={4}>
              {isLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {results && results.map((result) => (
                    <>
                      <Box mb={2}>
                        <Typography
                          variant="h4"
                          color="textSecondary"
                          // color="textPrimary"
                          className={classes.groupTitle}
                        >
                          {titles[result.type]}
                        </Typography>
                        {result.items.map(item => (
                          <Box mb={2}>
                            <Link
                              variant="body1"
                              color="textPrimary"
                              // color="textSecondary"
                              component={RouterLink}
                              to={buildPath(result.type, item.id)}
                            >
                              {item.name}
                            </Link>
                          </Box>
                        ))}
                        <Divider />
                      </Box>
                    </>
                  ))}
                </>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
}

export default Search;
