import React, { useCallback, useEffect, useMemo, useState } from 'react';
import L from 'leaflet';
import MapIconButton from "../MapIconButton";
import { makeStyles } from '@mui/styles';
import { POSITION_CLASSES } from './positions';

const useButtonStyles = makeStyles({
  editButton: {
    paddingBottom: 1
  },
  confirmButton: {
    '.leaflet-bar &': {
      color: 'green'
    }
  },
  cancelButton: {
    '.leaflet-bar &': {
      color: 'red'
    }
  }
});

export default function LocationControl({ position, onEditChange, onPositionChange, onPositionDelete, markerPosition }) {
  const classes = useButtonStyles();
  const [editing, setEditing] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [prevMarkerPosition, setPrevMarkerPosition] = useState(null);

  const containerRef = useCallback((node) => {
    if (node)
      L.DomEvent.disableClickPropagation(node);
  }, []);

  const toggleEditing = useCallback(() => {
    if (!updated)
      setEditing(editing => !editing);
  }, [updated]);

  const handleCancel = () => {
    onPositionChange && onPositionChange(prevMarkerPosition);
    setUpdated(false);
    // setEditing(false);
  };

  const handleConfirm = () => {
    setUpdated(false);
    setEditing(false);
    setPrevMarkerPosition(markerPosition);
  };

  const handleDelete = () => {
    setUpdated(false);
    setEditing(false);
    setPrevMarkerPosition(null);
    onPositionDelete && onPositionDelete();
  };

  useEffect(() => {
    if (editing)
      setPrevMarkerPosition(markerPosition);
    onEditChange && onEditChange(editing);
  }, [editing]);

  // const markerPosition = markerRef.current?.getLatLng();
  useEffect(() => {
    if (!markerPosition || !prevMarkerPosition)
      return;

    if (editing && !updated) {
      if (markerPosition.lat != prevMarkerPosition.lat
        || markerPosition.lng != prevMarkerPosition.lng)
        setUpdated(true);
    }
  }, [markerPosition?.lat, markerPosition?.lng]);

  const buttons = useMemo(() => (<>
    <MapIconButton title="Redigera kartpunkt" onClick={toggleEditing} icon="location-pen" className={classes.editButton} disabled={updated} />
    {editing && <>
      <MapIconButton title="Ångra" onClick={handleCancel} icon="location-xmark" className={classes.cancelButton} disabled={!updated} />
      <MapIconButton title="Godkänn" onClick={handleConfirm} icon="location-check" className={classes.confirmButton} disabled={!updated} />
    </>}
  </>), [editing, updated]);

  const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.topright;

  return (
    <div className="leaflet-control-container" ref={containerRef}>
      <div className={positionClass}>
        <div className="leaflet-control leaflet-bar">
          {buttons}
        </div>
        <div className="leaflet-control leaflet-bar">
          <MapIconButton title="Ta bort kartpunkt" onClick={handleDelete} icon="trash-xmark" />
        </div>
      </div>
    </div>
  );
}


