import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const TimestampGuard = ({ children }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const timestamp = params.get('ts');

  if (!timestamp) {
    return <Navigate to="/login" />;
  }

  // Skapa nuvarande svensk tid
  const currentTime = new Date().getTime(); // Lokaltid i millisekunder

  // Kontrollera tidsdifferensen i minuter
  const timeDifference = (currentTime - Number(timestamp)) / (1000 * 60); // minuter

  if (timeDifference > 10) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default TimestampGuard;
