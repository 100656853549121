import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, Marker, TileLayer, Popup, useMapEvents, ZoomControl, FeatureGroup } from 'react-leaflet';
import geo from './geo.json';
import DraggableMarker, { markerNotificationClasses } from "./DraggableMarker";
import LocationControl from "./controls/LocationControl";
import CountryClickMask from "./CountryClickMask";
import L from 'leaflet';
import './map.css';
import Control from 'react-leaflet-custom-control'

// import "leaflet/dist/leaflet.css";
// import "leaflet.markercluster/dist/MarkerCluster.css";
// import "leaflet.markercluster/dist/MarkerCluster.Default.css";

import MarkerClusterGroup from '../MarkerClusterGroup';
import SizeControl from './controls/SizeControl';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { maxHeight } from '@mui/system';
// import { MarkerClusterGroup } from 'leaflet';
// import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import { FullscreenControl } from './controls/FullscreenControl';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { renderToStaticMarkup } from 'react-dom/server';
import Icon from '../Icon';
import FilterControl from './controls/FilterControl';
import MapIconButton from './MapIconButton';
import { OBJECT_TYPES } from 'src/constants';
import { darken } from '@mui/material/styles';

// import MarkerClusterGroup from 'react-leaflet-markercluster';
// import MarkerClusterGroup from '@changey/react-leaflet-markercluster';

const useMapStyles = makeStyles(theme => ({
  '@global': {
    '.leaflet-container': {
      '& .leaflet-popup-content-wrapper': {
        backgroundColor: theme.palette.background.paper
      },
      '& .leaflet-popup-tip': {
        backgroundColor: theme.palette.background.paper
      },
      '& a.leaflet-popup-close-button': {
        color: theme.palette.text.secondary
      },
      '& .leaflet-popup-title': {
        marginBottom: theme.spacing(1),
        display: 'flex'
      },
      '& .leaflet-popup-subtitle': {
        marginBottom: theme.spacing(2),
        fontSize: '0.9rem',
        whiteSpace: 'pre'
      },
      '& .leaflet-popup-title-icon': {
        marginRight: theme.spacing(1)
      },
      '& .leaflet-popup-message-list': {
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
        borderRadius: 4,
        backgroundColor: theme.palette.background.dark,
        '& .leaflet-popup-message-list-item': {
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          color: theme.palette.text.primary
        }
      },
      '& .leaflet-popup-property-list': {
        marginBottom: theme.spacing(2),
        '& .leaflet-popup-property-list-item': {
          paddingTop: theme.spacing(0.5),
          paddingBottom: theme.spacing(0.5),
          color: theme.palette.text.primary
        }
      },
      '& .leaflet-popup-message-wrapper': {
        display: 'flex',
        padding: theme.spacing(1),
        paddingRight: theme.spacing(1) + 4,
        marginBottom: theme.spacing(2),
        color: theme.palette.text.primary,
        borderRadius: theme.spacing(0.5),
        // backgroundColor: theme.palette.background.dark,
        backgroundColor: theme.palette.type == 'light' ? '#e3e8ed' : theme.palette.background.dark,
        '&.leaflet-popup-message-notification': {
          backgroundColor: theme.palette.primary.main,
          color: 'white'
        },
        '&.leaflet-popup-message-warning': {
          backgroundColor: '#f4e400',
          color: 'black'
        },
        '&.leaflet-popup-message-error': {
          backgroundColor: 'orangered',
          color: 'white'
        },
        '& .leaflet-popup-message-icon-wrapper': {
          marginRight: theme.spacing(1),
          display: 'flex',
          alignItems: 'center',
          height: '1.5rem',
          '& .leaflet-popup-message-icon': {
          }
        },
        '& .leaflet-popup-message-text': {
          margin: 0,
          whiteSpace: 'pre'
        }
      }
    }
  }
}));

export default function MyMapTest({ className, markerPosition, onMarkerPositionChange, onMarkerDelete, onMapReady }) {
  const [editActive, setEditActive] = useState(false);

  const memoizedControls = useMemo(() => {
    return <LocationControl
      onEditChange={setEditActive}
      onPositionDelete={onMarkerDelete}
      position="topright"
      markerPosition={markerPosition}
      onPositionChange={onMarkerPositionChange} />;
  }, [markerPosition]);

  const handleReady = (e) => {
    onMapReady && onMapReady(e.target);

  };

  return <MapContainer
    whenReady={handleReady}
    className={className}
    style={{ height: 400 }}
    center={markerPosition || { lat: 57.40946113558119, lng: 15.578613281250002 }}
    zoom={markerPosition ? 16 : 6}
    scrollWheelZoom={true}
  >
    <TileLayer
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    <DraggableMarker draggable={editActive} onPositionChange={onMarkerPositionChange} position={markerPosition} setMarkerPosition={onMarkerPositionChange} />
    {markerPosition ? memoizedControls : null}
    <CountryClickMask geoJson={geo[0].geojson} />
  </MapContainer>;
}

export function NewMap({
  onMapReady,
  className,
  height: _height,
  maxHeight,
  markers,
  center,
  zoom,
  controlled,
  controlledHandlers,
  markerPosition,
  cover
}) {
  useMapStyles();
  const [editActive, setEditActive] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [markerFilter, setMarkerFilter] = useState([
    OBJECT_TYPES.PROPERTY,
    OBJECT_TYPES.MECHANICALROOM,
    OBJECT_TYPES.CHARGINGPOINT
  ]);

  const [map, setMap] = useState(null);
  const height = cover ? '100%' : _height;

  const handleTransitionEnd = () => {
    map.invalidateSize();
    map._container.removeEventListener('transitionend', handleTransitionEnd);
    map._container.style.transition = undefined;
  }

  const addControlPlaceholders = (map) => {
    const corners = map._controlCorners,
      l = 'leaflet-',
      container = map._controlContainer;

    function createCorner(vSide, hSide) {
      const className = l + vSide + ' ' + l + hSide;

      corners[vSide + hSide] = L.DomUtil.create('div', className, container);
    }

    createCorner('verticalcenter', 'left');
    createCorner('verticalcenter', 'right');
  }

  const fullscreenControlContent = useMemo(() => {
    return renderToStaticMarkup(<div id="leaflet-control-fullscreen-icon-wrapper">
      <Icon icon="expand-wide" />
    </div>);
  }, []);

  const fullscreenIcon = useMemo(() => {
    return renderToStaticMarkup(<Icon icon="expand-wide" />);
  }, []);

  const fullscreenIconCancel = useMemo(() => {
    return renderToStaticMarkup(<Icon icon="compress-wide" />);
  }, []);

  const handleEnterFullscreen = () => {
    document.getElementById("leaflet-control-fullscreen-icon-wrapper").innerHTML = fullscreenIconCancel;
  };

  const handleExitFullscreen = () => {
    document.getElementById("leaflet-control-fullscreen-icon-wrapper").innerHTML = fullscreenIcon;
  };

  useEffect(() => {
    if (map) {
      addControlPlaceholders(map);
      L.DomUtil.addClass(map.fullscreenControl.link, 'leaflet-icon-button');

      map.on('enterFullscreen', handleEnterFullscreen)
      map.on('exitFullscreen', handleExitFullscreen)

      return () => {
        L.DomUtil.removeClass(map.fullscreenControl.link, 'leaflet-icon-button');

        map.off('enterFullscreen', handleEnterFullscreen)
        map.off('exitFullscreen', handleExitFullscreen)
      }
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      map._container.style.height = `${expanded ? (maxHeight || 600) : (height || 400)}px`;
      map._container.style.transition = 'height 250ms ease-out';
      map._container.addEventListener('transitionend', handleTransitionEnd);

      return () => map._container.removeEventListener('transitionend', handleTransitionEnd);
    }
  }, [expanded]);

  function getUnderlyingMarkers(cluster) {
    return [...cluster._markers, ...cluster._childClusters.map(c => getUnderlyingMarkers(c))].flat()
  }

  function createClusterIcon(cluster) {
    // console.log({ cluster });
    const markers = getUnderlyingMarkers(cluster);

    const severity = Math.max(...markers.map(m => m.options.messageSeverity));
    const className = clsx('leaflet-custom-cluster-marker', markerNotificationClasses[severity]);

    return L.divIcon({
      className,
      iconSize: {
        x: 32,
        y: 32
      },
      html: cluster._childCount
    })
  }

  const uniqueMarkerTypes = markers ? [...new Set(markers.map(m => m.type))] : [];

  // console.log({ uniqueMarkerTypes, markers, markerFilter });

  const memoizedLocationControl = useMemo(() => {
    return controlled ? <LocationControl
      onEditChange={setEditActive}
      onPositionDelete={controlledHandlers && controlledHandlers.onMarkerDelete}
      position="topright"
      markerPosition={markerPosition}
      onPositionChange={controlledHandlers && controlledHandlers.onMarkerPositionChange} /> : null;
  }, [markerPosition]);

  const memoizedFilterControl = useMemo(() => {
    return <FilterControl
      position="verticalcenterright"
      filter={markerFilter}
      removeFilterList={markerFilter.filter(f => uniqueMarkerTypes.includes(f))}
      onFilterChange={setMarkerFilter}
    />;
  }, []);

  return <MapContainer
    ref={setMap}
    // zoomSnap={0.1}
    whenReady={onMapReady}
    className={className}
    style={{
      height: height || 400
    }}
    center={center
      ? center
      : Boolean(controlled && markerPosition)
        ? markerPosition
        : { lat: 57.40946113558119, lng: 15.578613281250002 }}
    zoom={zoom
      ? zoom
      : Boolean(controlled && markerPosition)
        ? 16
        : 6}
    scrollWheelZoom={true}
  >
    <TileLayer
      attribution='&copy; <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    <FullscreenControl
      content={fullscreenControlContent}
      forceSeparateButton
    />
    {controlled
      ? <>
        <DraggableMarker
          draggable={editActive}
          onPositionChange={controlledHandlers && controlledHandlers.onMarkerPositionChange}
          position={markerPosition}
        />
        {markerPosition ? memoizedLocationControl : null}
      </>
      : memoizedFilterControl
    }
    {!cover &&
      <SizeControl onExpandedChange={setExpanded} position="bottomleft" />
    }
    <MarkerClusterGroup
      iconCreateFunction={createClusterIcon}
      maxClusterRadius={30}
      showCoverageOnHover={false}
      spiderfyOnMaxZoom={true}
    >
      {/* <FeatureGroup> */}
      {markers && markers
        .filter(marker => markerFilter.includes(marker.type))
        .map(marker => <DraggableMarker
          key={`${marker.position.lat}, ${marker.position.lng}`}
          {...marker}
        />)}
      {/* </FeatureGroup> */}
    </MarkerClusterGroup>
    <CountryClickMask geoJson={geo[0].geojson} />
  </MapContainer>;
}

function TestControl() {
  useEffect(() => {
    L.Control.extend({})
  }, []);
  return L.Control.extend({});
}