import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getBase64 } from 'src/utils/avatar';
import axios from 'src/utils/axios';

const useStyles = makeStyles((theme) => ({
  root: {},
  name: {
    marginTop: theme.spacing(1)
  },
  avatar: {
    height: 100,
    width: 100
  }
}));

function ProfileDetails({ user, className, ...rest }) {
  const classes = useStyles();
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [avatarExists, setAvatarExists] = useState(true);
  const inputFileRef = createRef(null);

  useEffect(() => {
    getBase64(user.id || 2).then(src => {
      setAvatarSrc(src?.length ? src : null)
      setAvatarExists(Boolean(src?.length));
    });
  }, [user.id]);

  const handleRemoveAvatar = () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseUrl}/api/user/deleteavatar/${user.id}`;
    axios.delete(url);
  };

  const handleUploadAvatar = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseUrl}/api/user/addavatar`;

    console.log("handleuploadavatar");

    const blob = await fetch(avatarSrc).then(r => r.blob());

    const formData = new FormData();
    formData.append('UserId', user.id );
    formData.append('files', blob);

    // console.log(blob);
    // console.log(user.id);
    // console.log(formData);

    axios.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" }
    });
  };

  useEffect(() => {
    if (avatarSrc && !avatarExists) {
      handleUploadAvatar();
      setAvatarExists(true);
    }
  }, [avatarSrc]);

  const clearImage = () => {
    if (avatarSrc) {
      URL.revokeObjectURL(avatarSrc);
      inputFileRef.current.value = '';
      setAvatarSrc(null);
      setAvatarExists(false);
      handleRemoveAvatar();
    }
  };

  const handleAvatarSelected = (event) => {
    const newImage = event.target?.files?.[0];

    if (newImage)
      setAvatarSrc(URL.createObjectURL(newImage));
  };

  const handleClick = (event) => {
    avatarSrc
      ? clearImage()
      : inputFileRef.current.click();
  };

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardContent>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="column"
          textAlign="center"
        >
          <Avatar
            className={classes.avatar}
            src={avatarSrc}
          />
          <Typography
            className={classes.name}
            gutterBottom
            variant="h3"
            color="textPrimary"
          >
            {`${user.firstName} ${user.lastName}`}
          </Typography>
          <Typography
            color="textPrimary"
            variant="body1"
          >
            {`${user.company}, ${user.city}`}
          </Typography>
        </Box>
      </CardContent>
      <CardActions style={{ justifyContent: 'center' }}>
        <input
          ref={inputFileRef}
          accept="image/*"
          hidden
          id="avatar-image-upload"
          type="file"
          onChange={handleAvatarSelected}
          onClick={clearImage}
        />
        <label htmlFor="avatar-image-upload">
          <Button
            fullWidth
            variant="text"
            onClick={handleClick}
          >
            {!avatarSrc ? 'Ladda upp bild' : 'Ta bort bilden'}
          </Button>
        </label>
      </CardActions>
    </Card>
  );
}

ProfileDetails.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired
};

export default ProfileDetails;
