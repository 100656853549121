import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Container
} from '@mui/material';
import Page from 'src/components/Page';
import { makeStyles } from '@mui/styles';
import { changePassword } from 'src/actions/accountActions';
import axios from 'src/utils/axios';

const useStyles = makeStyles(() => ({
  root: {}
}));

function ChangePasswordForm({ className, ...rest }) {
  const { user } = useSelector(state => state.account);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const urlParams = new URLSearchParams(window.location.search);

  const queryString = location.search;
  const getQueryParam = (param) => {
    const match = queryString.match(new RegExp(`[?&]${param}=([^&]*)`));
    return match ? decodeURIComponent(match[1]) : null; // Avkoda om det finns
  };

  return (
    <Page className={classes.root} title="Ändra lösenord">
    <Container maxWidth="lg">
    <Box mt={3}>
    <Formik
      initialValues={{
        password: '',
        passwordConfirm: ''
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(8, 'Måste vara minst 8 tecken')
          .max(255)
          .required('Vänligen ange ett lösenord'),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Det angivna lösenorden måste vara samma')
          .required('Vänligen ange ett lösenord')
      })}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          const i = getQueryParam('i');
          const ets = getQueryParam('ets');
          const ts = getQueryParam('ts');
          const u = getQueryParam('u');

          const response = await axios.get('https://api.ipify.org?format=json');
          const ipAddress = response.data.ip;

          const baseUrl = process.env.REACT_APP_API_BASE_URL;
          await axios.post(`${baseUrl}/api/PasswordReset/forgotchangepassword?u=${u}&i=${i}&ts=${ts}&ets=${ets}`, {
            update: {
            password: values.password,
            passwordConfirm: values.passwordConfirm,
            ipAddress: ipAddress
            }
          });
          resetForm();
          setStatus({ success: true });
          enqueueSnackbar('Lösenordet är uppdaterat', {
            variant: 'success'
          });
        } catch (error) {
          setStatus({ success: false });

          enqueueSnackbar('Något gick fel. Du måste använda samma enhet som du begärde lösenordsbyte från!', {
            variant: 'error'
          });

          if (error.response && error.response.data.Message) {
            setErrors({ submit: error.response.data.Message });
          }
          else {
            setErrors({ submit: error.message });
          }
        } finally {
          setSubmitting(false);
        }

      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Card
            className={clsx(classes.root, className)}
            {...rest}
          >
            <CardHeader title="Ändra lösenord" />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label="Lösenord"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                >
                  <TextField
                    error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                    fullWidth
                    helperText={touched.passwordConfirm && errors.passwordConfirm}
                    label="Bekräfta lösenord"
                    name="passwordConfirm"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="password"
                    value={values.passwordConfirm}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {errors.submit && (
                <Box mt={3}>
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
            </CardContent>
            <Divider />
            <Box
              p={2}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                color="secondary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                Ändra lösenord
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
    </Box>
    </Container>
    </Page>
  );
}

ChangePasswordForm.propTypes = {
  className: PropTypes.string
};

export default ChangePasswordForm;
