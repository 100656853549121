/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';


import Logo from 'src/components/Logo';
import '@progress/kendo-theme-material/dist/all.css';
import axios from 'src/utils/axios';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { USER_ROLES } from 'src/constants';
import NavItem from './NavItem';
import 'src/utils/fontawesome';
import AvatarImage from 'src/components/AvatarImage';
import latestUpdate from 'src/config/latestUpdate';
import { useNavigation } from 'src/hooks/useNavigation';


// function renderNavItems({ items, ...rest }) {
//   return (
//     <List disablePadding>
//       {items.reduce(
//         (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
//         []
//       )}
//     </List>
//   );
// }

// function reduceChildRoutes({
//   acc,
//   pathname,
//   item,
//   depth = 0
// }) {
//   const key = `${item.id}_${item.type}_${depth}`;


//   if (item.items) {
//     const open = matchPath(pathname, {
//       path: item.href,
//       exact: false
//     });


//     acc.push(
//       <NavItem
//         depth={depth}
//         iconstring={item.icon}
//         key={key}
//         info={item.info}
//         open={Boolean(open)}
//         title={item.title}
//       >
//         {renderNavItems({
//           depth: depth + 1,
//           pathname,
//           items: item.items
//         })}
//       </NavItem>
//     );
//   } else {
//     acc.push(
//       <NavItem
//         depth={depth}
//         iconstring={item.icon}
//         key={key}
//         info={item.info}
//         href={item.href}
//         title={item.title}
//       />
//     );
//   }

//   return acc;
// }

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 300
  },
  desktopDrawer: {
    width: 300,
    top: 75,
    height: 'calc(100% - 75px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const menuStates = {};


function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const location = useLocation();
  const navigation = useNavigation();
  const { user } = useSelector((state) => state.account);
  const [menuitems2, setMenuitems2] = useState([]);
  // const key = ``;
  // const [menuStates, setMenuStates] = useState({ [key]: true });
  const isMountedRef = useIsMountedRef();
  const theme = useTheme();

  const getMenuItems = useCallback(async () => {
    let url = process.env.REACT_APP_API_BASE_URL;

    if (user.usertype === USER_ROLES.PROPERTYOWNERADMIN) {
      url += '/api/menu/menuitems';
    } else if (user.usertype === USER_ROLES.SUPERADMIN) {
      url += '/api/menu/allmenuitems';
    }

    await axios
      .get(url)
      .then((response) => {
        if (isMountedRef.current) {
          const newPropertyOwnerLink = {
            title: 'Ny fastighetsägare',
            id: 0,
            href: '/app/createpropertyowner',
            icon: 'plus-circle'
          };

          if (user.usertype === USER_ROLES.SUPERADMIN)
            response.data.items = [newPropertyOwnerLink, ...response.data.items];

          setMenuitems2([response.data]);
        }
      });
  }, [isMountedRef, user.usertype]);

  useEffect(() => {
    getMenuItems();
  }, [getMenuItems]);

  const toggleMenuState = (key, state) => {
    // console.log({ key, state })
    menuStates[key] = state;
    // setMenuStates(prevStates => ({ ...prevStates, [key]: state }));
  };


  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const handlePath = (href) => {
    if (!href)
      return;

    const keywords = ['propertyowner', 'property'];
    const hrefParts = href.split('/');
    const locationParts = location.pathname.split('/');

    for (const keyword of keywords) {
      if (hrefParts.indexOf(keyword) !== -1 && locationParts.indexOf(keyword) !== -1) {
        if (!Number.isInteger(+locationParts[locationParts.length - 1]) && Number.isInteger(+hrefParts[hrefParts.length - 1]))
          href += '/' + locationParts[locationParts.length - 1];
        return href + location.search;
      }
    }

    return href;
  };

  const NavItems = React.memo(({ items, pathname, depth }) => {
    return (
      <List disablePadding>
        {items && items.map(item => {
          const key = `${item.id}_${item.type}_${depth}`;
          // const open = matchPath({
          //   pathname,
          //   exactPath: false
          // })
          const open = menuStates[key];
          return <NavItem
            depth={depth}
            // href={item.href + (shouldKeepQuery(item.href) ? location.search : '')}
            // href={item.href}
            href={handlePath(item.href)}
            iconstring={item.icon}
            key={key}
            open={open}
            info={item.info}
            title={item.title}
            onToggle={toggleMenuState}
            itemKey={key}
          >
            {item.items && <NavItems items={item.items} depth={depth + 1} />}
          </NavItem>
        })}
      </List>
    );
  }, () => true);

  const PropertyOwners = React.memo(function PropertyOwners() {
    return (
      <NavItem
        depth={0}
        iconstring="briefcase"
        key="propertyownerkey"
        title="Kundobjekt"
        open={menuStates['propertyownerkey']}
        onToggle={toggleMenuState}
      >
        <>
          {menuitems2.map((config) => (
            <List
              key={config.subheader + config.id}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {config.subheader}
                </ListSubheader>
              )}
            >
              {/* <NavItem depth={1} title={"Test"} pathname="/app/createpropertyowner" /> */}
              <NavItems items={config.items} pathname={location.pathname} depth={1} />
              {/* {renderNavItems({ items: config.items, pathname: location.pathname, depth: 1 })} */}
            </List>
          ))}
        </>
      </NavItem>
    );
  });

  const TestItem = ({ ...rest }) => {
    return <NavItem
      {...rest}
    />;
  }

  const MemoizedAvatar = React.memo(({ id }) => {
    return <AvatarImage
      // alt="User"
      className={classes.avatar}
      id={id}
    />;
  }, () => true);

  const DrawerContent = React.memo(({ themeType }) => {
    return (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Hidden lgUp>
            <Box
              p={2}
              display="flex"
              justifyContent="center"
            >
              <RouterLink to="/">
                <Logo themeColor={themeType == 'light'} />
              </RouterLink>
            </Box>
          </Hidden>
          <Box p={2}>
            <Box
              display="flex"
              justifyContent="center"
            >
              <RouterLink to="/app/account">
                {/* <AvatarImage
                  // alt="User"
                  className={classes.avatar}
                  id={user.id}
                /> */}
                <MemoizedAvatar id={user.id} />
                {/* <Avatar
                  alt="User"
                  className={classes.avatar}
                  src={user.avatar}
                /> */}
              </RouterLink>
            </Box>
            <Box
              mt={2}
              textAlign="center"
            >
              <Link
                component={RouterLink}
                to="/app/account"
                variant="h5"
                color="textPrimary"
                underline="none"
              >
                {`${user.firstName} ${user.lastName}`}
              </Link>
              <Typography
                variant="body2"
                color="textSecondary"
              >
                {user.bio}
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box p={2}>

            <List key="dashboard_main">
              <NavItem
                depth={0}
                iconstring="home"
                key="dashboardmenuitemkey"
                title="Dashboard"
                href="/app/dashboard"
              />
              {user.usertype === USER_ROLES.SUPERADMIN && (
                <>
                  <PropertyOwners />
                </>
              )}
              {user.usertype === USER_ROLES.PROPERTYOWNERADMIN
                && menuitems2.map((config) => (
                  <List
                    key="propertieskey"
                    style={{ padding: 0 }}
                    subheader={(
                      <ListSubheader
                        disableGutters
                        disableSticky
                      >
                        {config.subheader}
                      </ListSubheader>
                    )}
                  >
                    <NavItems items={config.items} pathname={location.pathname} depth={0} />
                  </List>
                ))}
              {user.usertype === USER_ROLES.SUPERADMIN && <NavItem
                depth={0}
                iconstring="users"
                key="usersmenuitemkey"
                title="Användare"
                href="/app/users"
              />}
              {user.usertype === USER_ROLES.SUPERADMIN && <NavItem
                depth={0}
                iconstring="plug"
                key="apiusersmenuitemkey"
                title="API Användare"
                href="/app/apiusers"
              />}
              {user.usertype === USER_ROLES.SUPERADMIN && <NavItem
                depth={0}
                iconstring="file-import"
                key="importexportkey"
                title="Import/Export"
                href="/app/import"
              />}
              {user.usertype === USER_ROLES.SUPERADMIN && <NavItem
                depth={0}
                iconstring="th-list"
                key="eventlogkey"
                title="Logg"
                href="/app/log"
              />}
              {user.usertype === USER_ROLES.SUPERADMIN && <NavItem
                depth={0}
                iconstring="monitor-waveform"
                key="statuseskey"
                title="Tjänststatus"
                href="/app/statuses"
              />}
              {(user.usertype === USER_ROLES.SUPERADMIN || user.usertype === USER_ROLES.PROPERTYOWNERADMIN) && <NavItem
                depth={0}
                iconstring="server"
                key="modbusstatuseskey"
                title="ModbusTCP Status"
                href="/app/modbusstatuses"
              />}
              {user.usertype === USER_ROLES.SUPERADMIN && <TestItem
                depth={0}
                iconstring="coins"
                key="debitkey"
                title="Debitering"
                href="/app/debit"
              />}
              {(user.usertype === USER_ROLES.SUPERADMIN || user.usertype === USER_ROLES.PROPERTYOWNERADMIN) && <NavItem
                depth={0}
                iconstring="map-location-dot"
                key="mapkey"
                title="Karta"
                href="/app/map"
              />}
              {/* {user.usertype === USER_ROLES.SUPERADMIN && <TestItem
                depth={0}
                iconstring="charging-station"
                key="chargingtagskey"
                title="Laddtaggar"
                href="/app/tags"
              />} */}
            </List>
          </Box>
          <Divider />


        </PerfectScrollbar>
        {user.usertype === USER_ROLES.SUPERADMIN && <Box padding={2}>
          <Typography color="textSecondary" variant="body2" align="center">
            Senast uppdaterad: {latestUpdate}
          </Typography>
        </Box>}
      </Box>
    );
  }, () => true);


  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <DrawerContent themeType={theme.palette.type} />
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          <DrawerContent />
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
