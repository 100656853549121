import moment from 'moment';
import axios from 'src/utils/axios';
import { getDateToString } from './date';

export const baseUrl = process.env.REACT_APP_API_BASE_URL;


// USER FUNCTIONS
export const getUsers = async () => {
  return (await axios.get(`${baseUrl}/api/user`)).data;
};

export const addUser = async (user) => {
  return axios.post(`${baseUrl}/api/user`, user);
};

export const updateUser = async (user) => {
  return axios.put(`${baseUrl}/api/user`, user);
};

export const deleteUser = async (userId) => {
  return axios.delete(`${baseUrl}/api/user/${userId}`)
};
// END USER FUNCTIONS


// PROPERTY OWNER FUNCTIONS
export const getPropertyOwners = async () => {
  return (await axios.get(`${baseUrl}/api/propertyowners`)).data;
};
// END PROPERTY OWNER FUNCTIONS

// CACHE FUNCTIONS
export const canReloadCache = async (id, type, fromDate, toDate) => {
  return (await axios.get(`${baseUrl}/api/metering/cacheupdater/check/${type}/${id}/${fromDate}/${toDate}`)).data
}

export const reloadCache = async (id, type, fromDate, toDate) => {
  return (await axios.get(`${baseUrl}/api/metering/cacheupdater/${type}/${id}/${fromDate}/${toDate}`)).data
}
// END CACHE FUNCTIONS

export const getLogs = async () => {
  return (await axios.get(`${baseUrl}/api/logs`)).data
};

export const searchLogs = async (search, lastPageId, pageSize, direction) => {
  const url = `${baseUrl}/api/logs/search?f=${search}&s=${pageSize}&d=${direction}` +
              (lastPageId ? `&l=${lastPageId}` : '');
  return (await axios.get(url)).data;
};

export const searchLogsCount = async (search, lastPageId, pageSize, direction) => {
  const url = `${baseUrl}/api/logs/search/count?f=${search}&s=${pageSize}&d=${direction}` +
              (lastPageId ? `&l=${lastPageId}` : '');
  return (await axios.get(url)).data;
};


export const getDebitMenu = async (date) => {
  console.log(date);
  return (await axios.get(`${baseUrl}/api/debit/debitmenu/${date}`)).data
};

export const getDebitMenuForPropertyOwner = async (date, propertyownerid) => {
  return (await axios.get(`${baseUrl}/api/debit/debitmenu/${date}/${propertyownerid}`)).data
};

export const getFileFormats = async () => {
  return (await axios.get(`${baseUrl}/api/export/availableparsers`)).data
};

export const approveDebit = async (propertyId, startDate, endDate) => {
  return (await axios.get(`${baseUrl}/api/debit/approvaldebit/${startDate}/${endDate}/${propertyId}`)).data
};

export const checkApproval = async (propertyId, startDate, endDate) => {
  return (await axios.get(`${baseUrl}/api/debit/checkapproval/${startDate}/${endDate}/${propertyId}`)).data
};

export const revokeApproval = async (propertyId, startDate, endDate) => {
  return (await axios.get(`${baseUrl}/api/debit/unlockdebit/${startDate}/${endDate}/${propertyId}`)).data
};

export const getDebitDays = async (date) => {
  return (await axios.get(`${baseUrl}/api/debit/debitdays/${date}`)).data
};

export const getDebitDaysForProperty = async (date, id) => {
  return (await axios.get(`${baseUrl}/api/debit/debitdays/${date}/${id}`)).data
};

export const getProperty = async (id) => {
  return (await axios.get(`${baseUrl}/api/properties/${id}`)).data
};

export const getProperties = async (id) => {
  return (await axios.get(`${baseUrl}/api/properties`)).data
};

export const getPropertiesByPropertyOwner = async (propertyOwnerId) => {
  return (await axios.get(`${baseUrl}/api/properties/bypropertyowner/${propertyOwnerId}`)).data
};

export const getPropertiesForOverview = async (propertyOwnerId) => {
  return (await axios.get(`${baseUrl}/api/properties/foroverview/${propertyOwnerId}`)).data
};

export const getApartmentsForOverview = async (propertyId) => {
  return (await axios.get(`${baseUrl}/api/apartments/foroverview/${propertyId}`)).data
};

export const getMarkerTypes = async () => {
  return (await axios.get(`${baseUrl}/api/markers/types`)).data
};

export const getMarkers = async () => {
  return (await axios.get(`${baseUrl}/api/markers`)).data
};

export const getMarkersForPropertyOwner = async (propertyOwnerId) => {
  return (await axios.get(`${baseUrl}/api/markers/propertyowner/${propertyOwnerId}`)).data
};

export const getMarkersForProperty = async (propertyId) => {
  return (await axios.get(`${baseUrl}/api/markers/property/${propertyId}`)).data
};

export const getMarkersForMechanicalRoom = async (mechanicalRoomId) => {
  return (await axios.get(`${baseUrl}/api/markers/mechanicalRoom/${mechanicalRoomId}`)).data
};

export const getMeteringHourValues = async (sensorId, plint, periodFrom, periodTo) => {
  periodFrom = encodeURIComponent(periodFrom);
  periodTo = encodeURIComponent(periodTo);
  return (await axios.get(`${baseUrl}/api/Sensors/${sensorId}/values?plint=${plint}&from=${periodFrom}&to=${periodTo}`)).data;
  const data = (await axios.get(`${baseUrl}/api/Sensors/${sensorId}/values?plint=${plint}&from=${periodFrom}&to=${periodTo}`)).data;
  const days = {};

  for (let i = 0; i < data.length; i++) {
    const hourRow = data[i];
    const date = hourRow.readTime.slice(0, 10);

    if (days[date] === undefined) {
      days[date] = {
        readDate: date,
        pulsesA: 0,
        pulsesB: 0,
        rawValueA: 0,
        rawValueB: 0,
        meterReadingA: 0,
        meterReadingB: 0,
        rows: []
      };
    }
    days[date].rows.push(hourRow);
    days[date].pulsesA += hourRow.pulsesA;
    days[date].pulsesB += hourRow.pulsesB;
    days[date].rawValueA += hourRow.rawValueA;
    days[date].rawValueB += hourRow.rawValueB;
    days[date].meterReadingA += hourRow.meterReadingA;
    days[date].meterReadingB += hourRow.meterReadingB;
  }
  return Object.values(days);
};

export const updateSensorValue = async (sensorId, plint, readTime, newValue) => {
  readTime = encodeURIComponent(readTime);
  return axios.post(`${baseUrl}/api/sensors/${sensorId}/updatevalue?plint=${plint}&time=${readTime}&value=${newValue}`);
};

export const addSensorValue = async (sensorId, plint, readTime, newValue) => {
  readTime = encodeURIComponent(readTime);
  newValue = encodeURIComponent(newValue);
  return axios.post(`${baseUrl}/api/sensors/${sensorId}/addvalue?plint=${plint}&time=${readTime}&value=${newValue}`);
};

export const updateSensorValues = async (sensorId, updates) => {
  return axios.post(`${baseUrl}/api/sensors/${sensorId}/updatevalues`, updates);
};

export const getTags = async (objectType, objectId) => {
  return (await axios.get(`${baseUrl}/api/tags/${objectType}/${objectId}`)).data;
};

export const getTagsForApartment = async (propertyId, apartmentNumber) => {
  return (await axios.get(`${baseUrl}/api/tags/byApartment/${propertyId}/${apartmentNumber}`)).data
};

export const addTag = async (tag) => {
  return (await axios.post(`${baseUrl}/api/tags`, tag)).data;
};

export const updateTag = async (tag) => {
  return axios.put(`${baseUrl}/api/tags`, tag);
};

export const bulkDeleteTags = async (tagIds) => {
  return axios.post(`${baseUrl}/api/tags/bulkdelete`, tagIds);
};

export const getMechanialRooms = async () => {
  return (await axios.get(`${baseUrl}/api/mechanicalRooms`)).data
};

export const getChargingStations = async (propertyId) => {
  return (await axios.get(propertyId
    ? `${baseUrl}/api/chargingPoints/${propertyId}`
    : `${baseUrl}/api/chargingPoints`
  )).data
};

export const addChargingStation = async (chargingStation) => {
  return (await axios.post(`${baseUrl}/api/chargingPoints`, chargingStation)).data;
};

export const updateChargingStation = async (chargingStation) => {
  return axios.put(`${baseUrl}/api/chargingPoints`, chargingStation);
};

export const bulkDeleteChargingStations = async (chargingStationIds) => {
  return axios.post(`${baseUrl}/api/chargingPoints/bulkdelete`, chargingStationIds);
};

export const sendChargingStationCommand = async (command) => {
  return axios.post(`${baseUrl}/api/chargingPoints/command`, command);
};

export const getSpotPriceForecast = async () => {
  return (await axios.get(`${baseUrl}/api/spotprice/forecast`)).data;
};

export const getStatuses = async () => {
  return (await axios.get(`${baseUrl}/api/status`)).data;
};

export const getStatusSummary = async () => {
  return (await axios.get(`${baseUrl}/api/status/summary`)).data;
};

export const getSpotPrices = async (date) => {
  return (await axios.get(`${baseUrl}/api/spotprice?date=${date}`)).data;
};

export const getSpotPriceDayAheadStatus = async () => {
  return (await axios.get(`${baseUrl}/api/spotprice/dayahead`)).data;
};

export const getStatistics = async (objectType, objectId) => {
  return (await axios.get(`${baseUrl}/api/statistics/${objectType}/${objectId}`)).data;
};

export const getLastReceivedMeasurementValuesByPropertyOwner = async (propertyOwnerId) => {
  return (await axios.get(`${baseUrl}/api/metering/GetLastReceivedValues/PropertyOwner/${propertyOwnerId}`)).data;
};

export const getLastReceivedMeasurementValuesByProperty = async (propertyId) => {
  return (await axios.get(`${baseUrl}/api/metering/GetLastReceivedValues/Property/${propertyId}`)).data;
};

export const getLastReceivedMeasurementValuesByApartment = async (apartmentId) => {
  return (await axios.get(`${baseUrl}/api/metering/GetLastReceivedValues/Apartment/${apartmentId}`)).data;
};

export const getLastReceivedMeasurementValuesByMechanicalRoom = async (mechanicalRoomId) => {
  return (await axios.get(`${baseUrl}/api/metering/GetLastReceivedValues/MechanicalRoom/${mechanicalRoomId}`)).data;
};

export const getTemperaturesForPropertyOverview = async (propertyId) => {
  return (await axios.get(`${baseUrl}/api/metering/temperatures/PropertyOverview/${propertyId}`)).data;
};

export const getTemperaturesForMechanicalRoomOverview = async (mechanicalRoomId) => {
  return (await axios.get(`${baseUrl}/api/metering/temperatures/MechanicalRoomOverview/${mechanicalRoomId}`)).data;
};

export const getTemperaturesForApartmentOverview = async (apartmentId) => {
  return (await axios.get(`${baseUrl}/api/metering/temperatures/ApartmentOverview/${apartmentId}`)).data;
};

const API = {
  getUsers,
  addUser,
  updateUser,
  removeUser: deleteUser,
  getPropertyOwners,
  canReloadCache,
  reloadCache,
  getLogs,
  searchLogs,
  searchLogsCount,
  getDebitMenu,
  getDebitMenuForPropertyOwner,
  getFileFormats,
  approveDebit,
  checkApproval,
  revokeApproval,
  getDebitDays,
  getDebitDaysForProperty,
  getProperty,
  getProperties,
  getPropertiesByPropertyOwner,
  getPropertiesForOverview,
  getApartmentsForOverview,
  getMeteringHourValues,
  updateSensorValue,
  updateSensorValues,
  addSensorValue,
  getTags,
  addTag,
  updateTag,
  bulkDeleteTags,
  getChargingStations,
  addChargingStation,
  updateChargingStation,
  bulkDeleteChargingStations,
  sendChargingStationCommand,
  getMechanialRooms,
  getMarkers,
  getMarkersForPropertyOwner,
  getMarkersForProperty,
  getMarkersForMechanicalRoom,
  getMarkerTypes,
  getSpotPriceForecast,
  getStatuses,
  getStatusSummary,
  getSpotPrices,
  getSpotPriceDayAheadStatus,
  getStatistics,
  getLastReceivedMeasurementValuesByPropertyOwner,
  getLastReceivedMeasurementValuesByProperty,
  getLastReceivedMeasurementValuesByMechanicalRoom,
  getLastReceivedMeasurementValuesByApartment,
  getTemperaturesForPropertyOverview,
  getTemperaturesForMechanicalRoomOverview,
  getTemperaturesForApartmentOverview,
  baseUrl
};

export default API;