import React from 'react';
import clsx from 'clsx';
import Icon from 'src/components/Icon';
import { makeStyles } from '@mui/styles';
import './map.css';

const useButtonStyles = makeStyles({
  iconButton: {
    '.leaflet-control &': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
});

export default function MapIconButton({ icon, title, className, onClick, disabled, allowClick, ...rest }) {
  const classes = useButtonStyles();

  const handleClick = (e) => {
    e.preventDefault();
    if (!disabled || allowClick)
      onClick && onClick();
  };

  return <a
    href="#"
    aria-label={title}
    className={clsx('leaflet-icon-button', className, disabled ? 'leaflet-disabled' : '')}
    onClick={handleClick}
    title={title}

    {...rest}
  >
    <Icon icon={icon} />
  </a>;
}
