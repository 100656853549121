import axios from 'src/utils/axios';

export const GET_STATISTICS = '@dashboard/get-statistics';



export function getStatistics(values) {

  return async (dispatch) => {   


    axios.interceptors.request.use(request => {
      console.log('Starting Request', request)
      return request
    })
    
    axios.interceptors.response.use(response => {
      console.log('Response:', response)
      return response
    })

    await axios.post(process.env.REACT_APP_API_BASE_URL + '/api/statistics', values)
    .then((response) => {
      if (response.data) {

        dispatch({
          type: GET_STATISTICS,
          payload: response.data
        });;
      }          
    });

  };
}
