export function sum(arr) {
  return arr.reduce((a, b) => a + b, 0);
}

export function average(arr) {
  return sum(arr) / arr.length;
}

export function median(arr) {
  return arr.length % 2 === 0
    ? (arr[Math.floor(arr.length / 2) - 1] + arr[Math.floor(arr.length / 2)]) / 2
    : arr[Math.floor(arr.length / 2)];
}

export function calculateQuartiles(values) {
  if (!Array.isArray(values) || values.length === 0)
    return [Number.NEGATIVE_INFINITY, Number.POSITIVE_INFINITY];
  const ordered = values.sort((a, b) => a - b);

  return ordered.length % 2 === 0
    ? [median(ordered.slice(0, ordered.length / 2)), median(ordered.slice(ordered.length / 2))]
    : [median(ordered.slice(0, ordered.length / 2)), median(ordered.slice(Math.floor(ordered.length / 2) + 1))];
}

export function calculateLimits(q1, q3, deviationFactor = 1.5, lowestAbsolute = Number.NEGATIVE_INFINITY, highestAbsolute = Number.POSITIVE_INFINITY) {
  const iqr = q3 - q1;
  return [
    Math.max(q1 - iqr * deviationFactor, lowestAbsolute),
    Math.min(q3 + iqr * deviationFactor, highestAbsolute)
  ];
}