export function defaultDisplayFormat(value, type) {
  switch (type) {
    case 'double':
    case 'float': return defaultFloatFormat(value);
    case 'date': return defaultDateFormat(value);
    default: return value ? value.toString() : '';
  }
}

export function defaultDateFormat(date) {
  return new Date(date).toLocaleString();
}

export function defaultFloatFormat(number) {
  if (!number && number !== 0 || isNaN(number))
    return undefined;
  return number.toFixed(2).toString().replace('.', ',');
}

export const dateFormat = {
  telerik: '{0:yyyy-MM-dd HH:mm}',
  moment: ''
}