export function makeHighlight(geoJson) {
  return {
    type: 'MultiPolygon',
    coordinates: [
      [
        [
          [-180, 90],
          [180, 90],
          [180, -90],
          [-180, -90]
        ],
      ],
      ...geoJson.coordinates
    ]
  }
}

const map = {
  makeHighlight
};

export default map;