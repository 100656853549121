import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { updateNotificationSettings } from 'src/actions/accountActions';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormHelperText,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {}
}));

function Notifications({ notificationsettings, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: notificationsettings.email,
        sms: notificationsettings.sms
      }}
      onSubmit={async (values, {
        resetForm,
        setErrors,
        setStatus,
        setSubmitting
      }) => {
        try {
          await dispatch(updateNotificationSettings(values));
          resetForm();
          setStatus({ success: true });
          enqueueSnackbar('Notifieringsinställningarna är uppdaterade', {
            variant: 'success'
          });
        } catch (error) {          
          setStatus({ success: false });
          
          if(error.response && error.response.data.Message)
          {
            setErrors({ submit: error.response.data.Message });
          }
          else
          {
            setErrors({ submit: error.message });
          }          
        } finally {
          setSubmitting(false);
        }
        
      }}

      >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        values
      }) => (
    <form onSubmit={handleSubmit}>
      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <CardHeader title="Notifieringar" />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={6}
            wrap="wrap"
          >
            <Grid
              item
              md={4}
              sm={6}
              xs={12}
            >
              <Typography
                gutterBottom
                variant="h6"
                color="textPrimary"
              >
                System
              </Typography>
              <Typography
                gutterBottom
                variant="body2"
                color="textSecondary"
              >
                Systemet kommer att använda sig av angiven e-post adress och mobiltelefon i användarkontot.
              </Typography>
              <div>
                <FormControlLabel
                  control={(
                    <Checkbox
                    checked={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="email"
                     />
                  )}
                  label="E-post"
                />
              </div>
              <div>
                <FormControlLabel
                  control={(
                    <Checkbox
                    checked={values.sms} 
                    onBlur={handleBlur}
                    onChange={handleChange}
                    name="sms"
                    />  
                  )}
                  label="SMS"
                />
              </div>              
            </Grid>            
          </Grid>
          {errors.submit && (
              <Box mt={3}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
        </CardContent>
        <Divider />
        <Box
          p={2}
          display="flex"
          justifyContent="flex-end"
        >
          <Button
            color="secondary"
            disabled={isSubmitting}
            type="submit"
            variant="contained"
          >
            Spara
          </Button>
        </Box>
      </Card>
    </form>
     )}
     </Formik>
  );
}

Notifications.propTypes = {
  className: PropTypes.string,
  notificationsettings: PropTypes.object.isRequired
};

export default Notifications;
