import React from 'react'
import { Link as RouterLink } from 'react-router-dom';
import DefaultButton from './DefaultButton'

export default function NavigationButton({ text, to, ...rest }) {
  return <DefaultButton
    component={RouterLink}
    to={to}
    text={text}
    {...rest}
  />
}
