import { Box, IconButton, Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme, withTheme } from '@mui/material';
import { Calendar } from '@mui/x-date-pickers';
import { Chart, ChartArea, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartSeriesItemTooltip, ChartTooltip, ChartValueAxis, ChartValueAxisItem } from '@progress/kendo-react-charts';
import moment from 'moment';
import React from 'react'
import { useState } from 'react';
import { useMemo } from 'react';
import { Component } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import CalendarPicker from 'src/components/CalendarPicker';
import CheckboxInput from 'src/components/CheckboxInput';
import Icon from 'src/components/Icon';
import Label from 'src/components/Label';
import API from 'src/utils/api';
import { getDateTimeFromAPIDate, getDateToString, startOfHour, startOfMonth } from 'src/utils/date';
import { dateFormat, defaultFloatFormat } from 'src/utils/format';
import { average } from 'src/utils/iqr';
import DashboardCard from './DashboardCard';
import DashboardCardTitle from './DashboardCardTitle';
import { makeStyles, withStyles } from '@mui/styles';

const useTableStyles = makeStyles((theme) => ({
  averageTitle: {
    textAlign: 'right',
    // marginBottom: theme.spacing(1),
  },
  averageContainer: {
    // maxWidth: 300,
    // display: 'flex',
    // marginTop: theme.spacing(1),
    // marginBottom: theme.spacing(3),
    // '& .MuiTypography-root': {
    //   marginBottom: theme.spacing(1)
    // },
    // '& .MuiTableContainer-root': {
    //   border: '1px solid rgba(81, 81, 81, 1)',
    //   borderRadius: 4
    // },
    // '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
    //   border: 'none'
    // },
    // '& .MuiTableCell-root': {
    //   padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`
    // }
    '& span': {
      marginTop: theme.spacing(1)
    }
  },
  averageLabel: {
    '&:not(:last-child)': {
      marginRight: theme.spacing(1)
    },
    '& b': {
      marginRight: theme.spacing(0.5)
    }
  },
  wrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: theme.spacing(1),
  },
  feesButton: {
    marginBottom: theme.spacing(1)
  },
  [theme.breakpoints.down('sm')]: {
    wrapper: {
      display: 'block'
    },
    averageTitle: {
      textAlign: 'left',
      marginTop: theme.spacing(1)
    }
  }
}));

const useChartStyles = makeStyles((theme) => ({
  tooltip: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    whiteSpace: 'nowrap'
  }
}));

const chartStyles = (theme) => ({
  tooltip: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    whiteSpace: 'nowrap'
  }
});

function DayScrubber({ date, onBackClick, onForwardClick, disabled, disableNext, onDateChange }) {
  const nextButtonDisabled = disableNext || moment().isSameOrBefore(date);
  const momentDate = moment(date);
  const today = moment().endOf('day');
  const isTodayOrTomorrow = momentDate.isSameOrAfter(moment().startOf('day'));
  const label = isTodayOrTomorrow
    ? momentDate < today
      ? 'Idag'
      : 'Imorgon'
    : date.format('YYYY-MM-DD');

  return (
    <Box display="flex" alignItems="center">
      <IconButton aria-label='Föregående dag' title='Föregående dag' color="secondary" onClick={onBackClick} disabled={disabled}>
        <Icon icon="chevron-left" size="sm" style={{ width: '1em' }} />
      </IconButton>
      <CalendarPicker date={date} onDateChanged={onDateChange} maxDate={today} resolution="day">
        <Link color="textPrimary" href="#">
          <Typography color="textPrimary">
            {label}
          </Typography>
        </Link>
      </CalendarPicker>
      <IconButton aria-label='Nästa dag' title='Nästa dag' disabled={nextButtonDisabled || disabled} color="secondary" onClick={onForwardClick}>
        <Icon icon="chevron-right" size="sm" style={{ width: '1em' }} />
      </IconButton>
      {/* <Calendar /> */}
    </Box>
  )
}

const formatTime = dateTime => {
  const _date = moment(getDateTimeFromAPIDate(dateTime));
  return `${_date.format('HH')}`;
}

const formatFromToTime = dateTime => {
  const _date = moment(getDateTimeFromAPIDate(dateTime));
  return `${_date.format('HH:mm')} - ${_date.add(1, 'hour').format('HH:mm')}`;
}

class SpotPriceChart extends Component {

  state = {
    shouldAnimate: true,
    currentHourIndex: this.getCurrentHourIndex(),
    intervalId: 0
  }

  constructor(props) {
    super(props);
    this.getCurrentHourIndex = this.getCurrentHourIndex.bind(this);
  }

  getCurrentHourIndex() {
    if (!this.props.items || Object.values(this.props.items).length === 0)
      return;

    const currentHour = startOfHour(new Date());
    const _currentHourIndex = Object.values(this.props.items)[0]
      .map(d => {
        const date = getDateTimeFromAPIDate(d.time);
        return startOfHour(date).getTime();
      })
      .indexOf(currentHour.getTime());
    return _currentHourIndex;
  }

  componentDidMount() {
    const intervalId = setInterval(() => {
      const currentHourIndex = this.getCurrentHourIndex();
      if (currentHourIndex !== this.state.currentHourIndex) {
        this.setState({
          ...this.state,
          currentHourIndex,
          shouldAnimate: false
        });
      }
    }, 10000);

    this.setState({
      ...this.state,
      intervalId,
      currentHourIndex: this.getCurrentHourIndex()
    })
  }

  componentDidUpdate(prevProps) {
    if (this.props.items !== prevProps.items) {
      this.setState({
        ...this.state,
        shouldAnimate: true,
        currentHourIndex: this.getCurrentHourIndex()
      })
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.items !== nextProps.items || this.state.currentHourIndex !== nextState.currentHourIndex;
  }

  render() {
    const { shouldAnimate, currentHourIndex } = this.state;
    const { theme, classes, items } = this.props;

    const Tooltip = ({ point, className }) => (
      <span className={classes.tooltip}>
        <b>{point.series.name}</b>{' '}
        {formatFromToTime(point.dataItem.time)}
        <br />
        <center>
          {defaultFloatFormat(point.value)} öre/kWh
          <br />
        </center>
      </span>
    );

    const seriesItems = Object.keys(items).map(areaKey => (
      <ChartSeriesItem
        key={areaKey}
        data={items[areaKey]}
        field="value"
        categoryField="time"
        type="line"
        style="smooth"
        // axis="price"
        name={areaKey}
      >
        <ChartSeriesItemTooltip className={classes.tooltip} render={Tooltip} />
      </ChartSeriesItem>
    ));

    const labelContent = e => formatTime(e.value);

    const plotBands = [{
      from: currentHourIndex,
      to: currentHourIndex + 1,
      color: 'rgb(88 80 236 / 10%)'
    }];

    return <Chart
      pannable={false}
      zoomable={false}
      transitions={shouldAnimate}
    >
      <ChartArea background={theme.palette.background.paper} />
      <ChartLegend
        position="bottom"
        labels={{ color: theme.palette.text.primary }}
      />

      <ChartTooltip />
      <ChartValueAxis>
        <ChartValueAxisItem
          name="price"
          title={{ text: 'Elpris (öre/kWh)' }}
          color={theme.palette.text.primary}
          line={false}
        // majorUnit={0.5}
        />
      </ChartValueAxis>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem
          labels={{
            content: labelContent,
            rotation: 'auto',
            color: theme.palette.text.primary
          }}
          plotBands={plotBands}
        ></ChartCategoryAxisItem>
      </ChartCategoryAxis>


      <ChartSeries>
        {seriesItems}
      </ChartSeries>
    </Chart>;
  }
}

const ThemedSpotPriceChart = withStyles(chartStyles, { withTheme: true })(SpotPriceChart);

function SpotPriceAverages({ items, date }) {
  const classes = useTableStyles();

  if (!items)
    return null;

  return <div>
    <Typography className={classes.averageTitle} variant="body2">Snittpriser (öre/kWh)</Typography>
    <div className={classes.averageContainer}>
      {Object.keys(items).map(areaKey => (
        <Label
          key={areaKey}
          className={classes.averageLabel}
        >
          <b>{areaKey}</b> {defaultFloatFormat(average(items[areaKey].map(i => i.value)))}
        </Label>
      ))}
    </div>
  </div>;
}

let _intervalId = undefined;

export default function SpotPrices() {
  const [spotPrices, setSpotPrices] = useState(null);
  const [dayAheadExists, setDayAheadExists] = useState(false);
  const [intervalId, setIntervalId] = useState(undefined);
  const [date, setDate] = useState(moment());
  const [isFetching, setIsFetching] = useState(false);
  const [addFees, setAddFees] = useState(false);
  const classes = useTableStyles();

  useEffect(() => {
    setIsFetching(true);
    API.getSpotPrices(date.format('YYYY-MM-DD')).then(spotPrices => {
      setSpotPrices(spotPrices);
      setIsFetching(false);
    })

    if (date.isSame(moment(), 'date')) {
      checkDayAheadStatus();
      _intervalId = setInterval(checkDayAheadStatus, 30000);
      setIntervalId(_intervalId);
    }
    else {
      clearInterval(intervalId);
    }

    return () => {
      // console.log("RUNNING CLEANUP");
      clearInterval(_intervalId);
    }
  }, [date]);

  // useEffect(() => {
  //   if (!spotPrices)
  //     return;

  //   setSpotPrices(spotPrices => {
  //     Object.keys(spotPrices).forEach(areaKey => {
  //       spotPrices[areaKey] = spotPrices[]
  //     })
  //   })
  // }, [addFees, spotPrices]);

  const checkDayAheadStatus = async () => {
    const status = await API.getSpotPriceDayAheadStatus();
    setDayAheadExists(status);
  };

  const calculatedSpotPrices = useMemo(() => {
    if (!spotPrices)
      return;

    const _spotPrices = { ...spotPrices };
    Object.keys(_spotPrices).forEach(areaKey => {
      _spotPrices[areaKey] = _spotPrices[areaKey].map(v => {
        return { ...v, value: addFees ? v.value + v.fees : v.value }
      });
    });
    return _spotPrices
  }, [addFees, spotPrices]);

  if (!calculatedSpotPrices)// || Object.keys(calculatedSpotPrices).length === 0)
    return null;

  // console.log({ calculatedSpotPrices });

  const handleSetDate = (date) => {
    setDate(moment(date));
  };

  const handleNavigationBack = () => {
    setDate(date.clone().subtract(1, 'day'))
  };

  const handleNavigationForward = () => {
    setDate(date.clone().add(1, 'day'))
  };

  const isToday = date.isSame(moment(), 'date');

  return <DashboardCard>
    <DashboardCardTitle title="Spotpriser" />
    <div className={classes.wrapper}>
      <DayScrubber
        date={date}
        onBackClick={handleNavigationBack}
        onForwardClick={handleNavigationForward}
        onDateChange={handleSetDate}
        disableNext={!dayAheadExists && isToday}
        disabled={isFetching}
      />
      <SpotPriceAverages items={calculatedSpotPrices} />
    </div>
    <CheckboxInput
      label="Visa inkl skatter och avgifter"
      className={classes.feesButton}
      checked={addFees}
      onChange={setAddFees}
    />
    <ThemedSpotPriceChart items={calculatedSpotPrices} />
  </DashboardCard>;
}
