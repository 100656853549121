import { Avatar } from '@mui/material';
import React, { memo, useEffect, useState } from 'react'
import { getBase64, getBlob } from 'src/utils/avatar';
import axios from 'src/utils/axios';

const AvatarImage = memo(({ id, ...rest }) => {
  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    getBase64(id).then(base64 => {
      setImageData(base64);
    });
  }, [id]);

  return (
    <Avatar
      src={imageData}
      {...rest}
    />
  )
}, (prev, next) => prev.id !== next.id);

export default AvatarImage