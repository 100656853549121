import React, { useEffect, useState } from 'react';
import { OBJECT_TYPES } from 'src/constants';
import API from 'src/utils/api';
import { NewMap } from './MyMapTest';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import { Center } from '../Center';
import Icon from 'src/components/Icon';
import clsx from 'clsx';

const severityIcons = {
  1: 'exclamation',
  2: 'triangle-exclamation',
  3: 'circle-exclamation',
};

const messageClasses = {
  1: 'leaflet-popup-message-notification',
  2: 'leaflet-popup-message-warning',
  3: 'leaflet-popup-message-error',
}

function Message({ icon: _icon, message, severity }) {
  const icon = severity > 0 ? severityIcons[severity] : _icon;
  const className = severity > 0 ? clsx('leaflet-popup-message-wrapper', messageClasses[severity]) : 'leaflet-popup-message-wrapper';


  return <div className={className}>
    <div className="leaflet-popup-message-icon-wrapper">
      <Icon icon={icon} className="leaflet-popup-message-icon" />
    </div>
    <Typography className="leaflet-popup-message-text">
      {message}
    </Typography>
  </div>;
}

function Popup({ icon, type, title, subtitle, id, parentId, messages }) {

  const formatRoute = () => {
    switch (type) {
      case OBJECT_TYPES.PROPERTY:
        return `/app/property/${id}`;
      case OBJECT_TYPES.MECHANICALROOM:
        return `/app/mechanicalroom/${id}`;
      case OBJECT_TYPES.CHARGINGPOINT:
        return `/app/property/${parentId}/chargingstations?c=${id}`;
    }
  }

  const typeTexts = {
    [OBJECT_TYPES.PROPERTY]: 'fastighet',
    [OBJECT_TYPES.MECHANICALROOM]: 'undercentral',
    [OBJECT_TYPES.CHARGINGPOINT]: 'laddstolpe',
  };

  return <>
    <Typography className="leaflet-popup-title" color="textPrimary" variant="h4">
      <Icon icon={icon} className="leaflet-popup-title-icon" /> {title}
    </Typography>
    <Typography className="leaflet-popup-subtitle" color="textSecondary" variant="h5">
      {subtitle}
    </Typography>
    {messages && messages.map(message => <Message
      key={`${message.message.length}-${message.severity}`}
      {...message}
    />)}
    <Center horizontal>
      <Button
        color="secondary"
        variant="contained"
        component={RouterLink}
        to={formatRoute()}
      >
        Gå till {typeTexts[type]}
      </Button>
    </Center>
  </>;
}

function renderBuildingPopupContent({ object: { id, ...property }, messages, type }, { icon }) {
  return <Popup
    id={id}
    icon={icon}
    title={property.name}
    subtitle={property.subtitle}
    type={type}
    messages={messages}
  />;
}

function renderMechanicalRoomPopupContent({ object: { id, ...mechanicalRoom }, messages, type }, { icon }) {
  return <Popup
    id={id}
    icon={icon}
    title={mechanicalRoom.name}
    subtitle={mechanicalRoom.subtitle}
    type={type}
    messages={messages}
  />;
}

function renderChargingStationPopupContent({ object: { id, ...chargingStation }, messages, type }, { icon }) {
  return <Popup
    id={id}
    icon={icon}
    title={chargingStation.serialNumber}
    parentId={chargingStation.propertyId}
    subtitle={chargingStation.subtitle}
    type={type}
    messages={messages}
  />;
}

function defaultMarker(lat, lng) {
  return {
    position: {
      lat,
      lng
    },
    icon: 'location-dot',
    type: null,
    iconSize: {
      x: 24,
      y: 24
    },
    iconAnchor: {
      x: 0.5,
      y: 0.5
    },
  };
}

const renderers = {
  [OBJECT_TYPES.PROPERTY]: renderBuildingPopupContent,
  [OBJECT_TYPES.MECHANICALROOM]: renderMechanicalRoomPopupContent,
  [OBJECT_TYPES.CHARGINGPOINT]: renderChargingStationPopupContent
}

export default function MasterMap({ height, cover }) {
  const [markers, setMarkers] = useState(null);

  useEffect(() => {
    const a = API.getMarkerTypes();
    const b = API.getMarkers();

    Promise.all([a, b]).then(results => {
      const types = results[0];
      const markers = results[1];

      if (!types || !markers)
        return;

      // console.log({ types, markers });

      const _markers = markers.map(({ latitude, longitude, ...m }) => {
        return {
          position: {
            lat: latitude,
            lng: longitude
          },
          ...m,
          ...types[m.type],
          popupContent: renderers[m.type](m, types[m.type])
        }
      });

      setMarkers(_markers);
    })
  }, []);

  return markers ? <NewMap markers={markers} height={height} cover={cover} /> : null;
}
