import React, { useState, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import axios from 'src/utils/axios';
import { startOfMonth, startOfYear, getDateToString } from 'src/utils/date';
import { USER_ROLES, OBJECT_TYPES } from 'src/constants';
import { makeStyles } from '@mui/styles';



import Label from 'src/components/Label';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  label: {
    marginLeft: theme.spacing(1)
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    height: 48,
    width: 48
  },
  table: {
    border: 'none'
  },
  tablecell: {
    border: 'none',
    padding: '0px'
  }


}));


function SystemStatistics({
  timeRange, timeRanges, className, ...rest
}) {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [statistics, setStatistics] = useState(null);
  const { user } = useSelector((state) => state.account);


  const getStatistics = useCallback(async () => {
    let dateValue;
    let objtype;
    let objid = 0;
    const trValue = timeRanges.filter((t) => t.text === timeRange).map((tv) => tv.value)[0];


    if (trValue === 'today') {
      dateValue = new Date();
    } else if (trValue === 'last_month') {
      dateValue = startOfMonth(new Date());
    } else if (trValue === 'last_year') {
      dateValue = startOfYear(new Date());
    }

    if (user.usertype === USER_ROLES.SUPERADMIN) {
      objtype = OBJECT_TYPES.ALL;
    } else if (user.usertype === USER_ROLES.PROPERTYOWNERADMIN) {
      objtype = OBJECT_TYPES.PROPERTYOWNER;


      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/api/account/acl`)
        .then((response) => {
          if (response.data && response.data.acl) {
            objid = parseInt((
              response.data.acl.filter((t) => t.objectType === objtype).map(
                (id) => id.objectId
              )), 10);
          }
        });
    }

    const values = {
      objecttype: objtype,
      objectid: objid,
      differencesince: getDateToString(dateValue)
    };


    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/api/statistics`, values)
      .then((response) => {
        if (isMountedRef.current) {
          setStatistics(response.data.statistics);
        }
      });
  }, [isMountedRef, timeRange, timeRanges, user]);




  useEffect(() => {
    let intervalId = 0;
    getStatistics();
    intervalId = setInterval(() => getStatistics(), 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [getStatistics, timeRange, timeRanges]);

  const getLabelColor = () => {
    const difference = statistics.totalNumberOfPropertyOwners.difference;
    if (difference === 0)
      return 'neutral';
    else if (difference > 0)
      return 'success';
    else
      return 'error';
  };

  if (!statistics) {
    return null;
  }

  const statisticsObjects = {
    sensors: {
      text: 'Antal sensorer',
      key: 'sensors',
      value: statistics.totalNumberOfSensors.value,
      difference: statistics.totalNumberOfSensors.difference
    },
    apartments: {
      text: 'Antal lägenheter',
      key: 'apartments',
      value: statistics.totalNumberOfApartments.value,
      difference: statistics.totalNumberOfApartments.difference
    },
    properties: {
      text: 'Antal fastigheter',
      key: 'properties',
      value: statistics.totalNumberOfProperties.value,
      difference: statistics.totalNumberOfProperties.difference
    },
    propertyOwners: {
      text: 'Antal fastighetsägare',
      key: 'propertyOwners',
      value: statistics.totalNumberOfPropertyOwners.value,
      difference: statistics.totalNumberOfPropertyOwners.difference
    },
  };

  const authorizedStatistics = (() => {
    const { propertyOwners, properties, apartments, sensors } = statisticsObjects;
    if (user.usertype === USER_ROLES.SUPERADMIN)
      return [propertyOwners, properties, apartments, sensors];
    if (user.usertype === USER_ROLES.PROPERTYOWNERADMIN)
      return [properties, apartments, sensors];
    if (user.usertype === USER_ROLES.ENDUSER)
      return [sensors];
  })();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Box flexGrow={1}>
        <Typography
          component="h3"
          gutterBottom
          variant="overline"
          color="textSecondary"
        >
          Systemstatistik
          {' '}
          {timeRange}
        </Typography>
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
        >
          <TableContainer>
            <Table className={classes.table} size="small">
              <TableBody>
                {authorizedStatistics.map(statistics => (
                  <TableRow key={statistics.key}>
                    <TableCell className={classes.tablecell}>
                      <Typography
                        variant="h4"
                        color="textPrimary"
                      >
                        {statistics.text}
                      </Typography>
                    </TableCell>
                    <TableCell align="right" className={classes.tablecell}>
                      <Typography
                        variant="h4"
                        color="textPrimary"
                      >
                        {statistics.value}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tablecell}>
                      <Label
                        className={classes.label}
                        color={getLabelColor()}
                      >
                        {statistics.difference > 0 ? '+' : ''}
                        {statistics.difference}
                        %
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Card>
  );
}

SystemStatistics.propTypes = {
  className: PropTypes.string,
  timeRanges: PropTypes.any,
  timeRange: PropTypes.any
};

export default SystemStatistics;
