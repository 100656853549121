import React, { useCallback, useEffect, useState } from 'react';
import L from 'leaflet';
import MapIconButton from "../MapIconButton";
import { POSITION_CLASSES } from './positions';

export default function SizeControl({ position, onExpandedChange }) {
  const [expanded, setExpanded] = useState(false);

  const containerRef = useCallback((node) => {
    if (node)
      L.DomEvent.disableClickPropagation(node);
  }, []);

  useEffect(() => {
    onExpandedChange && onExpandedChange(expanded)
  }, [expanded]);

  const handleExpandedToggle = () => {
    setExpanded(expanded => !expanded);
  };

  const positionClass = (position && POSITION_CLASSES[position]) || POSITION_CLASSES.bottomleft;

  return (
    <div className="leaflet-control-container" ref={containerRef}>
      <div className={positionClass}>
        <div className="leaflet-control leaflet-bar">
          <MapIconButton
            title="Öka storlek"
            onClick={handleExpandedToggle}
            icon={expanded
              ? 'arrow-down-left-and-arrow-up-right-to-center'
              : 'arrow-up-right-and-arrow-down-left-from-center'} />
        </div>
      </div>
    </div>
  );
}
