import { Button } from '@mui/material'
import React from 'react'

export default function DefaultButton({ text, ...rest }) {
  return <Button
    variant="contained"
    color="secondary"
    {...rest}
  >
    {text}
  </Button>
}
