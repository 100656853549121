import { CardActionArea, CardActions, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import React from 'react'
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import DefaultButton from 'src/components/DefaultButton';
// import { Table } from 'react-virtualized';
import NavigationButton from 'src/components/NavigationButton';
import { APPLICATION } from 'src/constants';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import API from 'src/utils/api';
import { ApplicationStatusLabel } from '../../components/ApplicationStatusLabel';
// import Typography from 'src/theme/typography';
import DashboardCard from './DashboardCard';
import DashboardCardTitle from './DashboardCardTitle';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(2)
  }
}));

const useTableStyles = makeStyles(() => ({
  table: {
    border: 'none'
  },
  tableCell: {
    border: 'none',
    padding: '0px'
  }
}));

function ApplicationStatusTable({ children }) {
  const classes = useTableStyles();

  return <TableContainer>
    <Table className={classes.table} size="small">
      <TableBody>
        {children}
      </TableBody>
    </Table>
  </TableContainer>
}

function ApplicationStatusRow({ name, statusCode }) {
  const classes = useTableStyles();

  return <TableRow>
    <TableCell className={classes.tableCell}>
      <Typography
        variant="h4"
        color="textPrimary"
      >
        {name}
      </Typography>
    </TableCell>
    <TableCell className={classes.tableCell}>
      <ApplicationStatusLabel statusCode={statusCode} />
    </TableCell>
  </TableRow>;
}

export default function ApplicationStatusSummary() {
  const classes = useStyles();
  const isMountedRef = useIsMountedRef();
  const [applicationStatuses, setApplicationStatuses] = useState(null);

  const applicationPartNiceName = applicationPartId => {
    switch (applicationPartId) {
      case APPLICATION.PORTAL:
        return "Portalen";
      case APPLICATION.DATABASE:
        return "Databasen";
      case APPLICATION.BACKGROUND_WORKER:
        return "Bakgrundsjobbet";
      case APPLICATION.BINARY_PARSER:
        return "Binary parser";
      default:
        return "";
    }
  }

  const getStatusSummary = useCallback(async () => {
    if (!isMountedRef.current)
      return;
    API.getStatusSummary().then(summary => {
      const _summary = summary.map(s => {
        return {
          name: applicationPartNiceName(s.application),
          statusCode: s.status
        }
      })
      setApplicationStatuses(_summary);
    });
  }, [isMountedRef]);

  useEffect(() => {
    let intervalId = 0;
    getStatusSummary();
    intervalId = setInterval(() => getStatusSummary(), 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, [getStatusSummary]);

  if (!applicationStatuses)
    return null;

  return (
    <DashboardCard>
      <DashboardCardTitle title="Statusöversikt" />
      <ApplicationStatusTable>
        {applicationStatuses.map(status => (
          <ApplicationStatusRow key={status.name} {...status} />
        ))}
      </ApplicationStatusTable>
      <NavigationButton to="/app/statuses" text="Se full status" className={classes.button} />
      {/* <DefaultButton text="Test" /> */}
    </DashboardCard>
  )
}
