import React, { useCallback, useMemo } from 'react';
import { GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import { makeHighlight } from 'src/utils/map';


export default function CountryClickMask({ geoJson }) {

  const memoizedHightlight = useMemo(() => makeHighlight(geoJson), []);

  const pathRef = useCallback(node => {
    if (node)
      L.DomEvent.disableClickPropagation(node);
  });

  return <GeoJSON
    ref={pathRef}
    bubblingMouseEvents={false}
    data={memoizedHightlight}
    pathOptions={{
      fillOpacity: 0.2,
      color: 'black',
      opacity: 0.3,
      weight: 2
    }} />;
}
