import React from 'react';
import {
    Container,
    Grid
  } from '@mui/material';
import Header from '../Header';
import SystemStatistics from '../SystemStatistics';
import { makeStyles } from '@mui/styles';
  

  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: '100%',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
    },
    container: {
      [theme.breakpoints.up('lg')]: {
        paddingLeft: 48,
        paddingRight: 48
      }
    }
  }));

function EndUserDashboardView({ timeRange, timeRanges, setTimeRange, className, ...rest }) {
    const classes = useStyles();

    return (        
        <Container
            maxWidth={false}
            className={classes.container}
        >
            <Header timeRange={timeRange} timeRanges={timeRanges} setTimeRange={setTimeRange}/>
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    lg={3}
                    sm={6}
                    xs={12}
                >
                    <SystemStatistics timeRange={timeRange} timeRanges={timeRanges}/>
                </Grid>          
            </Grid>
        </Container>  
    );

}
export default EndUserDashboardView;