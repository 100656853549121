import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'nprogress/nprogress.css';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import 'src/mixins/prismjs';
//import { enableES5 } from 'immer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import * as serviceWorker from 'src/serviceWorker';
import { SettingsProvider } from 'src/context/SettingsContext';
import { configureStore } from 'src/store';
import { restoreSettings } from 'src/utils/settings';
import axios from 'src/utils/axios';
import App from 'src/App';

//enableES5();

const store = configureStore();
const settings = restoreSettings();

if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_LOG_REQUESTS === 'true') {
  axios.interceptors.request.use((request) => {
    // eslint-disable-next-line no-console
    console.log('Starting Request', request);
    return request;
  });
  axios.interceptors.response.use((response) => {
    // eslint-disable-next-line no-console
    console.log('Response:', response);
    return response;
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));

// ReactDOM

root.render(
  <Provider store={store}>
    <SettingsProvider settings={settings}>
      <App />
    </SettingsProvider>
  </Provider>
);

serviceWorker.unregister();
