import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React from 'react';
// import { ReactComponent as ReactLogo } from 'public/static/images/eye.svg';

const useStyles = makeStyles(theme => ({
  logoContainer: {
    padding: 10.5,
    paddingLeft: 0
  },
  logoColor: ({ themeColor }) => ({
    WebkitMask: 'url(/static/images/path4531_50.png) no-repeat',
    backgroundColor: themeColor && theme.palette.primary.main
  }),
  logo: {
    height: 50,
    filter: 'invert()',
    mixBlendMode: 'multiply'
  }
}));

function Logo({ className, themeColor, ...rest }) {
  const classes = useStyles({ themeColor });
  return (
    <div className={classes.logoContainer}>
      <div className={classes.logoColor}>
        <img
          alt="Logo"
          src="/static/images/path4531_50.png"
          className={clsx(classes.logo, className)}
          {...rest}
        />
      </div>
    </div>
  );
}

export default Logo;
