import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { createStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import Page from 'src/components/Page';
import SuperAdminDashboard from './SuperAdminDashboardView';
import PropertyOwnerAdminDashboard from './PropertyOwnerAdminDashboardView';
import EndUserDashboard from './EndUserDashboardView';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 48,
      paddingRight: 48
    }
  }
}));

const timeRanges = [
  {
    value: 'today',
    text: 'Idag'
  },
  {
    value: 'last_month',
    text: 'Senaste månaden'
  },
  {
    value: 'last_year',
    text: 'Senaste året'
  }
];

function DashboardView() {
  const classes = useStyles();
  const [timeRange, setTimeRange] = useState(timeRanges[1].text);
  const { user } = useSelector((state) => state.account);



  function dashboardSelector() {
    switch (user.usertype) {
        case 2:
            return (<SuperAdminDashboard timeRange={timeRange} timeRanges={timeRanges} setTimeRange={setTimeRange} />);
        case 1:
            return (<PropertyOwnerAdminDashboard timeRange={timeRange} timeRanges={timeRanges} setTimeRange={setTimeRange}/>);
        case 0:
            return (<EndUserDashboard timeRange={timeRange} timeRanges={timeRanges} setTimeRange={setTimeRange}/>);
        default:
            return [];
    }
}

const dashboard = dashboardSelector();


  return (
    <Page
      className={classes.root}
      title="Dashboard"
    >                
      {dashboard}
    </Page>
  );
}

export default DashboardView;
