import { alpha, Card, darken, Paper, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
// import { useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { getSpotPriceForecast } from 'src/utils/api';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeriesItemTooltip,
  ChartTooltip,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartArea,
  ChartCategoryAxisCrosshair,
  ChartAxisDefaults,
  ChartTitle
} from '@progress/kendo-react-charts';
import 'hammerjs';
import { getDateTimeFromAPIDate, getDateToShortMonthString, getDateToString, startOfMonth } from 'src/utils/date';
import { defaultFloatFormat } from 'src/utils/format';
import CheckboxInput from 'src/components/CheckboxInput';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    '& .k-widget.k-chart:not(:last-of-type)': {
      // marginBottom: theme.spacing(3),
    },
    '& .k-chart': {
      height: 300,
      // borderWidth: theme.spacing(0.5),
      // borderStyle: 'solid',
      // boxShadow: '0 0 4px #00000080',
      // borderColor: theme.palette.type == 'light' ? darken(theme.palette.background.dark, 0.04166666666) : theme.palette.background.dark,
      borderRadius: theme.spacing(0.5),
      '& .k-chart-surface > svg': {
        borderRadius: theme.spacing(0.5),

      }
    },
  },
  chartContainer: {
    '&:not(:last-of-type)': {
      marginBottom: theme.spacing(3)
    }
  },
  explainingText: {
    marginBottom: theme.spacing(3)
  },
  checkboxContainer: {
    marginBottom: theme.spacing(1)
  }
}));

const useChartStyles = makeStyles((theme) => ({
  tooltip: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize
  }
}));

function SpotPriceChartSingle({ data, title }) {
  const theme = useTheme();
  const classes = useChartStyles();

  const Tooltip = ({ point }) => (
    <span className={classes.tooltip}>
      <b>{point.series.name}</b>{' '}
      {getDateToString(getDateTimeFromAPIDate(point.dataItem.date))}
      <br />
      <center>
        {defaultFloatFormat(point.value)} {point.dataItem.unit}
        <br />
      </center>
    </span>
  );

  const labelContent = e => getDateToString(getDateTimeFromAPIDate(e.value));

  // console.log("PALETTE", theme.palette);

  return <Chart
    pannable={false}
    zoomable={false}

  // className={classes.chart}
  >
    <ChartTitle
      text={title}
      color={theme.palette.text.primary}
    />
    <ChartArea background={theme.palette.background.paper} />
    <ChartLegend
      position="bottom"
      visible={false}
      labels={{ color: theme.palette.text.primary }}
    />

    <ChartTooltip />
    <ChartValueAxis>
      <ChartValueAxisItem
        name="price"
        title={{ text: 'Elpris (öre/KWh)' }}
        color={theme.palette.text.primary}
        line={false}
        // majorUnit={0.5}
      />
    </ChartValueAxis>
    <ChartCategoryAxis>
      <ChartCategoryAxisItem
        labels={{
          content: labelContent,
          rotation: 'auto',
          color: theme.palette.text.primary
        }}
      ></ChartCategoryAxisItem>
    </ChartCategoryAxis>


    <ChartSeries>
      <ChartSeriesItem
        color={theme.palette.primary.main}
        data={data}
        field="price"
        categoryField="date"
        type="line"
        style="smooth"
        axis="price"
        visibleInLegend={true}

      >
        <ChartSeriesItemTooltip render={Tooltip} />
      </ChartSeriesItem>
    </ChartSeries>
  </Chart>;
}

function SpotPriceChartMultiple({ items }) {
  const theme = useTheme();
  const classes = useChartStyles();

  const formatDate = date => {
    const _date = getDateTimeFromAPIDate(date);
    return `${_date.getFullYear()} ${getDateToShortMonthString(_date)}`;
  };

  const Tooltip = ({ point }) => (
    <span className={classes.tooltip}>
      <b>{point.series.name}</b>{' '}
      {formatDate(point.dataItem.date)}
      <br />
      <center>
        {defaultFloatFormat(point.value)} {point.dataItem.unit}
        <br />
      </center>
    </span>
  );

  const labelContent = e => formatDate(e.value);

  const seriesItems = items.map(item => (
    <ChartSeriesItem
      key={item.name}
      data={item.data}
      field="price"
      categoryField="date"
      type="line"
      style="smooth"
      // axis="price"
      name={item.name}
    >
      <ChartSeriesItemTooltip render={Tooltip} />
    </ChartSeriesItem>
  ));

  const currentMonth = startOfMonth(new Date());
  const currentMonthIndex = items[0].data
  .map(d => {
    const date = getDateTimeFromAPIDate(d.date);
    return new Date(date.getFullYear(), date.getMonth(), 1).getTime();
  })
  .indexOf(currentMonth.getTime());
  const plotBandColor = 'rgb(88 80 236 / 10%)';

  return <Chart
    pannable={false}
    zoomable={false}
  >
    <ChartArea background={theme.palette.background.paper} />
    <ChartLegend
      position="bottom"
      labels={{ color: theme.palette.text.primary }}
    />

    <ChartTooltip />
    <ChartValueAxis>
      <ChartValueAxisItem
        name="price"
        title={{ text: 'Elpris (öre/kWh)' }}
        color={theme.palette.text.primary}
        line={false}
        // majorUnit={0.5}
      />
    </ChartValueAxis>
    <ChartCategoryAxis>
      <ChartCategoryAxisItem
        labels={{
          content: labelContent,
          rotation: 'auto',
          color: theme.palette.text.primary
        }}
        plotBands={[{
          from: currentMonthIndex,
          to: currentMonthIndex+1,
          color: plotBandColor
        }]}
      ></ChartCategoryAxisItem>
    </ChartCategoryAxis>


    <ChartSeries>
      {seriesItems}
    </ChartSeries>
  </Chart>;
}



export default function SpotPricesForecast() {
  const [spotPrices, setSpotPrices] = useState(null);
  const [spotPriceSeries, setSpotPriceSeries] = useState({
    se1: [],
    se2: [],
    se3: [],
    se4: []
  });
  const [includeFee, setIncludeFee] = useState(true);
  const [includeVat, setIncludeVat] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    getSpotPriceForecast().then(spotPrices => {
      setSpotPrices(spotPrices);
    });
  }, []);

  useEffect(() => {
    if (!spotPrices)
      return;

    const mapArea = (area) => spotPrices.map(spotPrice => ({
      price: (spotPrice[area].price + spotPrice.fee * includeFee) * (1 + spotPrice.vat * includeVat),
      date: spotPrice.date,
      unit: spotPrice.unit
    }));

    const se1 = mapArea('sE1');
    const se2 = mapArea('sE2');
    const se3 = mapArea('sE3');
    const se4 = mapArea('sE4');

    setSpotPriceSeries({ se1, se2, se3, se4 });
  }, [spotPrices, includeFee, includeVat]);

  const { se1, se2, se3, se4 } = spotPriceSeries;

  if ([se1, se2, se3, se4].flat().length == 0)
    return null;

  const AreaChart = ({ data, title }) => {
    if (!data || data.length === 0)
      return null;
    return <Paper elevation={2} className={classes.chartContainer}>
      <SpotPriceChartSingle data={data} title={title} />
    </Paper>;
  };

  const AreaChartMultiple = ({ items }) => {
    return <SpotPriceChartMultiple items={items} />
  };

  return (
    <Card className={classes.root}>
      <Typography
        component="h3"
        gutterBottom
        variant="overline"
        color="textSecondary"
      >
        Prognos för elpriser
      </Typography>
      <Typography className={classes.explainingText}>
        Prognos över kommande månaders snittpriser för de olika elområdena. Prognosen bygger på Nasdaqs terminshandel och är för marknaden ett förväntat snittpris över månaden.
      </Typography>
      <div className={classes.checkboxContainer}>
        <CheckboxInput label="Räkna med påslag" checked={includeFee} onChange={setIncludeFee} />
        <CheckboxInput label="Räkna med moms" checked={includeVat} onChange={setIncludeVat} />
      </div>
      <AreaChartMultiple
        items={[
          {
            name: 'SE1',
            data: se1
          },
          {
            name: 'SE2',
            data: se2
          },
          {
            name: 'SE3',
            data: se3
          },
          {
            name: 'SE4',
            data: se4
          },
        ]}
      />
      {/* <AreaChart data={se1} title="SE1" />
      <AreaChart data={se2} title="SE2" />
      <AreaChart data={se3} title="SE3" />
      <AreaChart data={se4} title="SE4" /> */}
    </Card>
  );
}
