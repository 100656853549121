import { useNavigate, useLocation } from "react-router";

const defaultOptions = {
  tabs: undefined
};

export function useNavigation(options) {
  const location = useLocation();
  const history = useNavigate();

  const { tabs } = { ...defaultOptions, ...options };

  function setPath(path, options) {
    const { preserveQuery } = options;

    let query = '';
    if (preserveQuery)
      query = location.search;

    history(path + query);
  }

  function setTab(tab) {
    const pathParts = location.pathname.split('/');
    let lastPart = pathParts.pop();

    if (tabs.map(tab => tab.value).indexOf(lastPart) === -1)
      lastPart = [lastPart, tab].join('/');
    else
      lastPart = tab;

    const url = [...pathParts, lastPart].join('/') + location.search;

    history(url, { replace: true });
  }

  return {
    setTab,
    setPath
  };
}