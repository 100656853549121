import React from 'react';
import { createControlComponent } from '../../../../node_modules/@react-leaflet/core/';
import 'leaflet.fullscreen';
import L from 'leaflet';
import Control from 'react-leaflet-custom-control';
import MapIconButton from '../MapIconButton';
import { useMap } from 'react-leaflet';

// export default function FullscreenControl(props) {
//   const map = useMap();

//   const handleToggleFullscreen = () => {
//     if (map)
//       map.toggleFullscreen();
//   }

//   React.useEffect(() => {
//     if (map) {
//       map.on('fullscreenchange', () => {
//         console.log("FULLSCREEN CHANGED");

//       })
//     }
//   }, [map])

//   return <Control position="topleft">
//     <MapIconButton onClick={handleToggleFullscreen} icon={map._isFullscreen ? 'gear' : 'building'} />
//   </Control>;
// }

const FullscreenControl = createControlComponent(props => L.control.fullscreen(props))
// // const FullscreenControl = createControlComponent(MyFullscreenControl)

export { FullscreenControl }